import moment from "moment";
import momentTz from 'moment-timezone';
import React, { useCallback, useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";

import Box from "@mui/material/Box";
import searchImg from "../../assets/search.svg";

import AppLayout from "../../components/AppLayout/AppLayout";
import CommonDatePicker from "../../components/CommonDatePicker/CommonDatePicker";
// import Bradcurm from "../../components/CommsnBradcurm/bradcurm";
// import { DownloadImg } from "../../components/CoustomIcons";
// import { useThemeContext } from "../../components/ThemeContext/themeContext";




import { BASE_URL } from '../../utils/Baseurl';
import { color } from "../../utils/Colors";
import { useGetReportsMutation } from "../../services/query";
import { Heading } from "../Dashboard/Dashboard.styles";
import { StyledSearchOutlinedInput } from "../../components/AppLayout/AppLayout.styles";
import ReportTable from "./reportTable";
import { useLocation } from "react-router-dom";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";

const Reports = () => {
    const [isCustom, setIsCustom] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusInput] = useState(null);
    const [timeLineTxt, setTimeLineTxt] = useState("Year to date");
    const [timemilliseconds, setTimeMilliseconds] = useState(15778800);
    const [reports, setReports] = useState(null);
    const [rows, setRows] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchValue, setSearchValue] = useState("");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const accountId = queryParams.get('accountId')
    const [getReports, { data: reportsData, error: reportsError, isLoading: reportsLoading },] = useGetReportsMutation();

    useEffect(() => {
        if (timemilliseconds) {
            getAnalytics();
        }
    }, [timemilliseconds]);

    useEffect(() => {
        if (
            reportsData !== undefined &&
            reportsData?.statusCode === 200 &&
            reportsData?.response &&
            reportsData?.response.adminReport
        ) {
            setReports(reportsData?.response?.adminReport);
            setRows(reportsData?.response?.adminReport);
            return
        }

        if (reportsError !== undefined) {
            setReports([]);
            setRows([]);
            console.log("reportsError", reportsError);
        }
    }, [reportsData, reportsError]);

    const requestSearch = (searchedVal) => {
        const filteredRows = reports?.filter((row) => {
            return (
                row.connectionName.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.connectionId.toLowerCase().includes(searchedVal.toLowerCase())
            );
        });
        setRows(filteredRows);
        setPage(0);
        setSearchValue(searchedVal);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getAnalytics = useCallback(() => {
        let sDate = parseInt(Date.now() / 1000 - timemilliseconds);
        let eDate = parseInt(Date.now() / 1000);
        const timezoneOffset = momentTz.tz.zone(momentTz.tz.guess()).offset(new Date()) * 60;

        if (timeLineTxt === "Year to date") {
            // Get current date
            const today = new Date();
            // Get current year
            const year = today.getFullYear();
            // Get first day of year
            const yearFirstDay = new Date(year, 0, 1);
            let milliseconds = (today.getTime() - yearFirstDay.getTime()) / 1000;
            sDate = parseInt(Date.now() / 1000 - milliseconds);
        }
        setStartTime(sDate);
        setEndTime(eDate);
        getReports({
            endDate: eDate,
            startDate: sDate,
            status: timeLineTxt,
            offset: timezoneOffset
        });
    }, [useGetReportsMutation, timemilliseconds, timeLineTxt]);

    const getAnalytics1 = useCallback(
        (startDate, endDate) => {
            setStartTime(startDate / 1000);
            setEndTime(endDate / 1000);
            const timezoneOffset = momentTz.tz.zone(momentTz.tz.guess()).offset(new Date()) * 60;
            getReports({
                endDate: parseInt(endDate / 1000),
                startDate: parseInt(startDate / 1000),
                status: timeLineTxt,
                offset: timezoneOffset
            });
        },
        [useGetReportsMutation, timemilliseconds, timeLineTxt]
    );

    const _downloadReport = useCallback((connectionId) => {
        const link = document.createElement('a');
        const timezoneOffset = momentTz.tz.zone(momentTz.tz.guess()).offset(new Date()) * 60;
        link.href = `${BASE_URL}/analytics/admin/report/download/${accountId}?startDate=${startTime}&endDate=${endTime}&status=${timeLineTxt}&connectionId=${connectionId}&offset=${timezoneOffset}`;
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link);
    },
        [BASE_URL, startTime, endTime, accountId]
    );
    console.log('rows', rows)
    return (
        <AppLayout>
            <Box px='1.5rem'>
                <Box display="flex" flexDirection="row" gap={2} justifyContent="space-between" mb={2} alignItems="center">
                    <SBreadCrumb mainPathName="Reports" mainPath="reports" />

                    <Box display="flex" >
                        {isCustom && (
                            <Box display="flex" flexDirection="column" alignItems="end" flex="auto" mr={2} sx={{ zIndex: 11 }}>
                                <DateRangePicker startDate={startDate} startDateId="startDate" endDate={endDate} endDateId="endDate" numberOfMonths={1}
                                    isOutsideRange={(day) => { return moment().diff(day) < 0; }} onDatesChange={({ startDate, endDate }) => {
                                        setStartDate(startDate); setEndDate(endDate);
                                        if (startDate !== null && endDate !== null) { getAnalytics1(startDate, endDate); }
                                    }} focusedInput={focusedInput}
                                    onFocusChange={(focusedInput) => setFocusInput(focusedInput)} />
                            </Box>
                        )}
                        <Box display="flex" alignItems="center">
                            <Box>
                                <CommonDatePicker sx={{ marginRight: "0px" }} timeLineTxt={timeLineTxt} setTimeLineTxt={setTimeLineTxt} setTimeMilliseconds={setTimeMilliseconds}
                                    getAnalytics={getAnalytics} setIsCustom={setIsCustom} />
                            </Box>
                            <StyledSearchOutlinedInput value={searchValue} placeholder="Search Here" onChange={(e) => requestSearch(e.target.value)}
                                inputProps={{ style: { paddingLeft: "1rem", padding: "5px", fontFamily: "Helvetica", fontWeight: 600 }, }}
                                startAdornment={<img alt="" src={searchImg} style={{ width: "1.25rem", height: "1.25rem", marginLeft: "7px" }}></img>}></StyledSearchOutlinedInput>
                        </Box>
                    </Box>
                </Box>

                <ReportTable rows={rows} rowsPerPage={rowsPerPage} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                    searchValue={searchValue} requestSearch={requestSearch} reportsLoading={reportsLoading} _downloadReport={_downloadReport} />
            </Box>
        </AppLayout>
    );
};

export default Reports;
