import { Box, FormControl, InputLabel, Typography } from "@mui/material";
import styled from "styled-components";
import Tab from '@mui/material/Tab';
import PhoneInput from "react-phone-input-2";

export const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: "25px !important",
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
  borderRadius: "20px !important",
  border: "1px solid #EEF3F8",
});
export const PhoneInputWrapper = styled(PhoneInput)({
  width: "100%",
  height: "2.75rem",
  fontSize: "0.875rem",
  color: "#666666",
  backgroundColor: "#FFFFFF",
  fontFamily: "Helvetica Neue",
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    // borderColor: Theme.FieldBorderColor,
  },
});
export const PrimaryContDetailsBox = styled(Box)({
  width: "100%",
  marginTop: "1.5rem",
  border: "1px solid #E4E4E4",
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  paddingBottom: "1.5rem",
  display: "flex",
  flexDirection: "column",
  boxShadow: "none !important"
});

export const SelfBox = styled(Box)({
  marginTop: "0.5rem",
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  height: "1.25rem",
});

export const Heading = styled(Typography)({
  fontSize: "1.125rem !important",
  fontFamily: "Helvetica Neue !important",
  fontWeight: "500 !important",
  letterSpacing: "0.2px !important",
});

export const CountrySelectWrapper = styled(Box)({
  display: "flex",
  marginTop: "1.5rem",
  width: "100%",
  height: "2.75rem",
  fontSize: "1rem",
  borderRadius: "4px",
  alignItems: "center",
  justifyContent: "center",

});
export const StyledInputLabelCountry = styled(InputLabel)(({ selected }) => ({
  position: "absolute !important",
  top: "5px !important",
  left: "-4px !important",
  fontSize: selected ? "0.75rem !important" : "1rem !important",
  color: selected ? "#0000004f !important" : "#00000099 !important",
  transition: "all 0.2s ease !important",
  pointerEvents: "none !important",
  background: "white !important",
  width: "80px !important",
  padding: "0px 6px !important",
  fontFamily: 'Helvetica Neue !important',
  display: selected ? "" : "none !important",
  // '&.Mui-focused': {
  //   color: 'blue',
  // },
}));

export const StyledFormControlCountry = styled(FormControl)({
  position: "relative !important",
  marginBottom: "24px !important",
});


export const BillContactWrapper = styled(Box)({
  display: "flex",
  marginTop: "1rem",
  width: "100%",
  justifyContent: "space-between",
})

export const SubmitBtnWrapper = styled(Box)({
  width: "100%",
  marginTop: "1.5rem",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
})

