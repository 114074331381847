import React from "react";
import {
  DownloadBtn,
  DownloadBtnWrapper,
  Heading,
  HeadingWrapper,
  LastUpdatedAt,
} from "./TermsCondition.styles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { fiveSixSevenArr } from "./constant";
import useDocumentTitle from "../../hook/useDocumentTitle";
import pdfWhiteImg from "../../assets/pdf_white.svg";
import { color } from "../../utils/Colors";
import { HOST } from "../../utils/Baseurl";
import { Link } from "react-router-dom";

const CommanTermsCondition = () => {
  useDocumentTitle("Madconnect Terms of Service");
  const matcheslg = useMediaQuery("(min-width:1100px)");
  const matchesmd = useMediaQuery("(min-width:980px)");
  const matchessm = useMediaQuery("(min-width:880px)");
  const matchesxs = useMediaQuery("(min-width:760px)");
  return (
    <>
      <HeadingWrapper>
        <Box sx={{ width: "91%", maxWidth: 1350 }}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Heading
              fontSize={
                matcheslg
                  ? "2.5rem"
                  : matchesmd
                  ? "2.3rem"
                  : matchessm
                  ? "2rem"
                  : matchesxs
                  ? "1.8rem"
                  : "1.6rem"
              }
            >
              MADCONNECT TERMS OF SERVICE
            </Heading>
            <DownloadBtnWrapper>
              <a
                style={{ color: color.whiteColor, textDecoration: "none" }}
                download={"MadTech_MC_Terms_of_Service_10_04_24.pdf"}
                target="_blank"
                href={`${HOST}/platforms/MadTech_MC_Terms_of_Service_10_04_24.pdf`}
              >
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <Typography>
                    <img
                      src={pdfWhiteImg}
                      style={{ maxWidth: "24px", maxHeight: "24px" }}
                    />
                  </Typography>
                  <DownloadBtn>DOWNLOAD</DownloadBtn>
                </Box>
              </a>
            </DownloadBtnWrapper>
          </Box>
          <LastUpdatedAt
            fontSize={
              matcheslg
                ? "1rem"
                : matchesmd
                ? "0.9rem"
                : matchessm
                ? "0.8rem"
                : matchesxs
                ? "0.7rem"
                : "0.7rem"
            }
          >
            Last Updated: 07 October, 2024
          </LastUpdatedAt>
        </Box>
      </HeadingWrapper>
      <Box
        sx={{
          py: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#eeeeee",
        }}
      >
        <Box
          sx={{
            width: "91%",
            maxWidth: 1350,
            backgroundColor: "#fff",
            p: 3,
            borderRadius: 2,
            border: "1px solid rgba(54, 70, 172, 0.24)",
          }}
        >
          <div>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="Generator" content="Microsoft Word 15 (filtered)" />
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n@font-face\n\t{font-family:"Noto Sans Symbols";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:170%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpFirst\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpMiddle\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpLast\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nspan.Heading1Char\n\t{mso-style-name:"Heading 1 Char";\n\tmso-style-link:"Heading 1";\n\tfont-family:"Arial",sans-serif;\n\tcolor:windowtext;\n\tfont-weight:bold;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:6.0pt;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:.5in 1.0in 1.25in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in; padding-left: 1rem;}\nul\n\t{margin-bottom:0in;}\n-->\n',
              }}
            />
            <div className="WordSection1">
              <p>
                These Terms of Service (these
                <span style={{ "font-weight": "bold" }}>“Terms"</span>) are a
                legal agreement between you (
                <span style={{ "font-weight": "bold" }}>“Client”, “you”</span>
                or
                <span style={{ "font-weight": "bold" }}>“your”</span>) and
                MadTech, LLC (
                <span style={{ "font-weight": "bold" }}>“MadTech”, “us” </span>
                or
                <span style={{ "font-weight": "bold" }}>“our”</span>) for use of
                the platform (the
                <span style={{ "font-weight": "bold" }}>
                  “MadConnect Platform”
                </span>
                ) licensed by MadTech, that solves for data platform
                interoperability by providing the integrations necessary for the
                transfer, normalization, and bi-directional communication
                between licensed data platforms across AdTech and MarTech, and
                enables clients to send and/or receive data to and/or from
                multiple third party platforms by means of a single application
                programming interface (the
                <span style={{ "font-weight": "bold" }}>“MadTech API”</span>).
                MadTech and you may each be referred to as a
                <span style={{ "font-weight": "bold" }}>“Party”</span> or
                collectively referred to as the
                <span style={{ "font-weight": "bold" }}>“Parties”</span>.
              </p>

              <p>
                <span style={{ "font-weight": "bold" }}>
                  These Terms Set Forth a Legally Binding Agreement
                </span>
              </p>
              <p className="MsoNormal">
                By accessing or using all or any portion of the Platform (as
                defined in Section 3.1 below), MadTech API and/or any other
                products or services provided by MadTech (collectively, the
                “Services”) as contemplated by your license agreement with
                MadTech (the
                <span style={{ "font-weight": "bold" }}>
                  “License Agreement”
                </span>
                ), by clicking
                <span style={{ "font-weight": "bold" }}>
                  “License Agreement”
                </span>
                , or “I agree”, or by paying for the Services offered by MadTech
                or its affiliates or partners (e.g., Snowflake), you acknowledge
                you have read and understand and to be bound by these Terms,
                including those additional terms and conditions and policies
                referenced herein and/or available by hyperlink. You represent
                and warrant that any individual clicking through and otherwise
                accepting these Terms on your behalf has the full and lawful
                authority to do so. If you do not agree with these Terms, then
                you may not access or use the Services. The terms and conditions
                of these Terms apply to any and all use of the Services by you
                or your authorized users, including if you are using the
                Services pursuant to any demo or trial period, for the term of
                these Terms and you, on your own behalf and on behalf of your
                users, agree and consent to be bound by the terms and conditions
                of these Terms regardless of the type of use of the Service by
                you. If these Terms are considered an offer, acceptance is
                expressly limited to these Terms.
              </p>
              <p>
                <span style={{ "font-weight": "bold" }}>These Terms</span>
              </p>
              <p className="MsoNormal">
                These Terms prevail over any of your general terms and
                conditions regardless of whether or when you have submitted your
                request for proposal, order, or such terms. Provision of
                Services to you does not constitute acceptance of any of your
                terms and conditions and does not serve to modify or amend these
                Terms. These Terms are solely between you and MadTech (and not
                any MadTech partners (e.g., Snowflake) and MadTech is solely
                responsible and liable for the Services and the obligations
                under these Terms.
                <span style={{ "font-weight": "bold" }}>
                  In the event of a direct conflict between these Terms and the
                  terms of the License Agreement, the License Agreement shall
                  govern.
                </span>
              </p>

              <p className="MsoNormal">&nbsp;</p>
              <ol style={{ "margin-top": "0in" }} start={1} type={1}>
                <li className="MsoNormal" style={{ "margin-bottom": "6.0pt" }}>
                  <b>THE SERVICES</b>
                </li>
              </ol>
              <p className="MsoNormal" style={{ "margin-bottom": "12.0pt" }}>
                MadTech operates the MadConnect Platform that solves for data
                platform interoperability by providing the integrations
                necessary for the transfer, normalization, and bi-directional
                communication between licensed data platforms across AdTech and
                MarTech, and enables clients to send and/or receive data to
                and/or from multiple third party platforms by means of the
                MadTech API. The MadConnect Platform is a self-service platform
                that enables Client to establish a connection (each a
                <span style={{ "font-weight": "bold" }}>“Connection”</span> )
                through the MadConnect Platform that results in the joining of
                two Connectors (one designated the source Connector, and the
                other designated the destination Connector) to send data from a
                source to a destination.
                <span style={{ "font-weight": "bold" }}>“Connector”</span> means
                a source or destination capability through the MadConnect
                Platform by which Client or a Client Customer, as the case may
                be, can send data to or receive data from a source or
                destination platform owned or operated by a third party that
                enables Client or a Client Customer, as the case may be, to send
                data to, or receive data from (a
                <span style={{ "font-weight": "bold" }}>
                  “Third Party Platform”
                </span>
                ) as designated from time to time by Client in the Client
                Account Dashboard. In order to establish each Connection through
                the MadConnect Platform, Client will be required to enter the
                unique API key provided to Client by the applicable Third Party
                Platform under its Third Party Platform Agreement (each a
                <span style={{ "font-weight": "bold" }}>“Client API Key”</span>
                and collectively
                <span style={{ "font-weight": "bold" }}>“Client API Keys”</span>
                ). Client shall be solely responsible for protecting and
                maintaining the security of the Client API Keys.
                <span style={{ "font-weight": "bold" }}>“Client Customer”</span>
                means a customer of Client that is provided access to the
                MadConnect Platform through an embedded link on Client’s website
                or via a Client-branded version of the MadConnect Platform
                <span style={{ "font-weight": "bold" }}>
                  (“White Label Platform”)
                </span>
                and is party to an agreement between Client and a Client
                Customer pursuant to which such Client Customer is provided
                access to the Platform (
                <span style={{ "font-weight": "bold" }}>
                  “Client Customer Agreement"
                </span>
                ).
                <span style={{ "font-weight": "bold" }}>
                  “Third Party Platform Agreement”
                </span>
                means an agreement between Client or a Client Customer, as the
                case may be, with a Third Party Platform related to and
                authorizing the transfer of data to and/or from such Third Party
                Platform.
              </p>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={2}>
                <li className="MsoNormal" style={{ marginBottom: "6.0pt" }}>
                  <b>CLIENT ACCOUNT; CLIENT CUSTOMER ACCOUNT</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>2.1 Client’s Account.</b>
                      <p
                        className="MsoNormal"
                        style={{ marginBottom: "12.0pt" }}
                      >
                        Client must create an account to access and use the
                        Services
                        <span style={{ fontWeight: "bold" }}> (“Account”)</span>
                        . Client agrees to provide accurate and complete Account
                        information including Client’s name and address, names
                        of Client’s employees, contractors, agents, or
                        representatives (collectively,{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          “Client Users”
                        </span>
                        ), Client email address, telephone number, and contact
                        information for administrative and accounting matters.
                        Client is solely responsible for all activities that
                        take place on or through its Account, including the acts
                        and omissions of any Client Users. Client shall, and
                        shall require all Client Users to, (i) establish,
                        maintain and utilize reasonable and appropriate security
                        measures with respect to Client’s or Client Users’
                        access to and use of (a) the Services, including using
                        appropriately complex passwords, keeping passwords
                        confidential, and regularly changing passwords, and (b)
                        any Client API Keys, and (ii) comply with MadTech’s
                        security guidelines and procedures applicable to the
                        Services and the use thereof. MadTech shall not be
                        responsible for any unauthorized access to Client’s
                        Account, including any unauthorized use of the Services
                        by Client or Client Users or any unauthorized access to
                        or use of any Client API Keys by any third party, and
                        Client shall indemnify, defend and hold MadTech harmless
                        from and against any and all losses, costs, and expenses
                        arising out of or resulting from any such unauthorized
                        access or use.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>2.2 Client Customer Account.</b>
                      <p
                        className="MsoNormal"
                        style={{ marginBottom: "12.0pt" }}
                      >
                        Client will, for itself and on behalf of the Client
                        Customers, set up, or may request MadTech to set up, a
                        Client Customer account for each Client Customer (a
                        “Client Customer Account”) within Client’s Account
                        dashboard on the Platform (the “Client Account
                        Dashboard”). Each Client Customer will thereafter have
                        the ability to select and establish its Connections
                        within its Client Customer Account.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={3}>
                <li className="MsoNormal" style={{ marginBottom: "6.0pt" }}>
                  <b>License Grant; Use Restrictions</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>3.1 License Grant.</b>
                      <p
                        className="MsoNormal"
                        style={{ marginBottom: "12.0pt" }}
                      >
                        Subject to the terms and conditions of these Terms
                        (including Client’s payment of the License Fees),
                        MadTech hereby grants to Client a non-exclusive,
                        worldwide, irrevocable, non-transferable license for the
                        Term (as hereinafter defined) to (i) access and use, as
                        applicable, the MadConnect Platform or the White Label
                        Platform (collectively, the
                        <span style={{ fontWeight: "bold" }}> “Platform”</span>)
                        for Client’s own internal business purposes, and (ii)
                        provide access to the Platform to Client Customers for
                        use for Client Customers’ internal business purposes.
                        Client’s use of the MadConnect Platform may, at Client’s
                        option, be via the self-service user interface, the
                        functionality of the MadConnect Platform built into
                        Client’s website as native capability via the MadTech
                        API (an
                        <span style={{ fontWeight: "bold" }}>
                          “Embedded Model”
                        </span>
                        ) or a White Label Platform.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>3.2 Use Restrictions.</b>
                      <p
                        className="MsoNormal"
                        style={{ marginBottom: "12.0pt" }}
                      >
                        You agree that by using the Services, you shall (i) be
                        responsible for your compliance with these Terms and all
                        activities occurring within or from your Account, (ii)
                        be solely responsible for the accuracy, quality,
                        integrity and legality of, and for the means by which
                        you acquired content or data, and your use of the
                        Services, (iii) use reasonable efforts to prevent
                        unauthorized access to or use of the Services, and
                        notify us promptly of any such unauthorized access or
                        use, and (iv) use the Services only in accordance with
                        these Terms and applicable laws and government
                        regulations. Client and the Client Customers shall use
                        the Platform solely to establish Connections with Third
                        Party Platforms with which Client or the Client
                        Customers have Third Party Platform Agreements, and only
                        for the number of Connections and Rows of Data for which
                        Client has paid License Fees under the Pricing Addendum
                        set forth in the License Agreement. Except as expressly
                        permitted in these Terms, you (a) may not make available
                        or use the Services for the benefit of any third party,
                        including, but not limited to, as a service bureau; (b)
                        may not sell, resell, license, sublicense, transfer,
                        distribute, make available, rent or lease the Services,
                        or exploit the Services for any commercial purposes; (c)
                        may not use the Services to store or transmit any
                        illegal, immoral, unlawful, offensive, obscene and/or
                        unauthorized materials or interfere with or violate a
                        third party’s rights to privacy and other rights, or
                        harvest or collect personally identifiable information
                        about third parties without their express consent; (d)
                        may not use the Services to transmit or otherwise make
                        available any malicious code, including any virus, worm,
                        trojan horse, time bomb, web bug, spyware, or any other
                        computer code, file, or program; (e) may not interfere
                        with or disrupt the integrity, performance, or operation
                        of the Services or any part thereof; (f) may not attempt
                        to gain unauthorized access or bypass any measures
                        imposed to prevent or restrict access to the Services;
                        (g) may not use or take any direct or indirect action
                        that imposes or circumvents any usage limits; (h) may
                        not copy (except for making a reasonable number of
                        copies for backup or archival purposes), modify,
                        distribute, create derivative works, translate, port,
                        reverse engineer, decompile, or disassemble any portion
                        of the Services, or any material that is subject to our
                        proprietary rights or use any of the foregoing to create
                        any software or service similar to the Services; (i) may
                        not use any information or materials of any user or
                        other third party appearing on or through the Services,
                        without our prior written consent; (j) may not
                        misrepresent or impersonate any person or provide
                        inaccurate Account information; or (k) use any robot,
                        bot, spider, crawler, scraper, site search/retrieval
                        application, proxy or other manual or automatic device,
                        method or process to access, retrieve, index, “data
                        mine,” or in any way reproduce or circumvent the
                        navigational structure or presentation of the Services
                        or its contents. Any breach of these Terms by you, as
                        shall be determined in our sole discretion, may result
                        in the immediate suspension or termination of your
                        Account.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>
              <ol style={{ "margin-top": "0in" }} start={4} type={1}>
                <li className="MsoNormal">
                  <b>CLIENT USAGE INFORMATION</b>
                </li>
              </ol>
              <p className="MsoNormal">
                The Services are designed to allow Client to access the Services
                via API(s) from Client or Client’s applications, web connected
                devices or web properties. You hereby grant MadTech and its
                subcontractors, a perpetual, irrevocable, paid-up, royalty-free,
                worldwide, non-exclusive right and license to collect, store and
                use information regarding Client’s use of the Services,
                including record of acceptance of these Terms, IP address, time
                of access, usernames, number of connections, number of
                transactions, and volume of data transmitted (
                <span style={{ "font-weight": "bold" }}>
                  “Client Usage Information”
                </span>
                ), during the Term of these Terms and following the termination
                or expiration of these Terms to maintain, improve and support
                the Service. MadTech will only share or disclose Client Usage
                Information with others under special circumstances as follows:
                (i) with third parties who work on MadTech’s behalf to provide
                the Services; (ii) to the extent needed to comply with laws or
                to respond to lawful requests and legal process (provided that
                MadTech will endeavor to notify Client if MadTech has received a
                lawful request for Client’s information); (iii) to protect the
                rights and property of MadTech, its agents, Clients, and others
                including to enforce MadTech’s agreements, policies, and terms
                of use; (iv) in an emergency, including to protect the personal
                safety of any person; (v) in connection with a sale or transfer
                of all or a part of MadTech’s business or assets (business deals
                may include, for example, any merger, financing, acquisition,
                divestiture, or bankruptcy transaction or proceeding); or (vi)
                as directed by Client, including through its use of the
                Services. Client acknowledges that MadTech may also use Client
                Usage Information in an anonymized and aggregated form (
                <span style={{ "font-weight": "bold" }}>“Aggregated Data”</span>
                ) for MadTech’s own business purposes, including use,
                duplication, modification and creation of derivative works
                regarding usage and performance of the Service.  MadTech shall
                own all right, title and interest to the Aggregated Data and any
                derivative works thereof or therefrom. MadTech will delete
                Client Usage Information upon Client’s request once MadTech’s
                business need for such information no longer exists (as
                determined in MadTech’s sole and reasonable discretion). Upon
                termination of MadTech’s relationship with Client, MadTech’s
                business need, or in accordance with MadTech’s data retention
                policy, MadTech may delete Client Usage Information no longer in
                active use. MadTech takes no responsibility and assumes no
                liability for any Client Usage Information.
              </p>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={5} type={1}>
                <li className="MsoNormal">
                  <b>DATA TRANSFER LIABILITY; THIRD PARTY PLATFORMS</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>5.1 Data Transfer Liability</b>
                      <p className="MsoNormal">
                        MadTech shall have no obligation or liability
                        whatsoever, under these Terms or otherwise, with respect
                        to or under any Third Party Platform or Third Party
                        Platform Agreement, or for the transfer of any data
                        between Client or a Client Customer and any Third Party
                        Platform. Client acknowledges and agrees that the
                        MadConnect Platform only provides automated mapping of
                        such data for the purpose of enabling the transfer of
                        data contemplated by these Terms and accordingly MadTech
                        will have no visibility into any data transferred by or
                        to Client or any Client Customer. Client acknowledges
                        and agrees that all data that is sent by Client or a
                        Client Customer to, and all data that is received by
                        Client or a Client Customer from, a Third Party Platform
                        via the Platform will at all times be in encrypted form.
                        For clarity, the foregoing shall not apply to any data
                        transferred to or from MadTech via a Client Connection
                        in connection with the transformation, normalization and
                        validation by MadTech of Client unstructured data in
                        accordance with a Client-provided schema and/or
                        specifications, for use by Client within systems owned
                        and operated by Client
                        <span style={{ fontWeight: "bold" }}>
                          (“Data Services”)
                        </span>
                        .
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>5.2 Third Party Platforms</b>
                      <p className="MsoNormal">
                        MadTech is not responsible or liable for the content or
                        data accessible through any Third Party Platforms
                        accessed by Client through the Services. Please review
                        carefully the third-party's policies and practices and
                        make sure you understand them before you engage in any
                        transaction. Complaints, claims, concerns, or questions
                        regarding third-party products or services should be
                        directed to the applicable third-party. Client
                        understands and acknowledges that Client is solely
                        responsible for all content and data it sends or
                        accesses through the Services, and Client, not MadTech,
                        has full responsibility for all such content and data,
                        including its dissemination, transfer, accessibility,
                        legality, reliability, accuracy, and appropriateness.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={6} type={1}>
                <li className="MsoNormal">
                  <b>Term; Termination; ENFORCEMENT</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>6.1 Term</b>
                      <p className="MsoNormal">
                        The term of these Terms shall commence on the date you
                        first use/access our Services  (the 
                        <span style={{ fontWeight: "bold" }}>
                         “Effective Date”
                        </span>
                        ) and shall continue until terminated by you or MadTech in
                        accordance with these Terms and/or the License Agreement ( the
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                           “Term”
                        </span>
                ).
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>6.2 Termination</b>
                      <p className="MsoNormal">
                        Either Party may terminate these Terms and/or the
                        License Agreement, as of the date specified in a notice
                        of termination, if the other Party fails to perform any
                        of its material obligations under these Terms and does
                        not cure such failure within thirty (30) days following
                        receipt of a written notice of such default. Either
                        Party may terminate these Terms if the other Party
                        becomes the subject of a petition in bankruptcy or any
                        other proceeding relating to insolvency, receivership,
                        liquidation or assignment for the benefit of creditors.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>6.3 Effect of Termination</b>
                      <p className="MsoNormal">
                        Upon the effective date of the termination of these
                        Terms or the License Agreement, you will immediately
                        cease having access to the Services, including any
                        Services purchased during the term of the License
                        Agreement or were unused during the term of the License
                        Agreement. Once cancellation of your Account is
                        confirmed, your information may be deleted from the
                        Services. We do not accept any liability for such loss.
                        Since deletion of all information is final, please be
                        sure that you do in fact want to cancel your Account
                        before doing so. Termination of your Account shall not
                        relieve you of your obligations to pay amounts accrued
                        or owing, nor affect any legal rights or obligations
                        which may have arisen under these Terms prior to or at
                        the date of termination. Without limiting the generality
                        of the foregoing, if (i) you terminate any Services,
                        except as specifically permitted for MadTech’s material
                        breach, or (ii) MadTech terminates these Terms and/or
                        the License Agreement due to your material breach, any
                        and all payment obligations of you in connection with
                        the Services shall become immediately due and payable
                        with respect to such Services.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>6.4 Enforcement</b>
                      <p className="MsoNormal">
                        MadTech may, in its sole discretion, immediately limit,
                        terminate or suspend Client’s or any Client User’s
                        access to all or part of the Service, with or without
                        notice, if MadTech determines that Client’s services,
                        offerings or activities violate these Terms. Without
                        limitation, MadTech also reserves the right to report
                        any activity (including the disclosure of appropriate
                        data) that it suspects may violate any law or regulation
                        to appropriate law enforcement officials, regulators, or
                        other appropriate third parties. MadTech also may
                        cooperate with appropriate law enforcement agencies to
                        assist in the investigation and prosecution of any
                        illegal conduct.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={7} type={1}>
                <li className="MsoNormal">
                  <b>PAYMENT</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>7.1 Fees; Billing.</b>
                      <p className="MsoNormal">
                        To access the Services, you agree to pay license fees
                        <span style={{ fontWeight: "bold" }}>
                          (“License Fees”)
                        </span>
                        , based on the Connector or Connection type(s), as
                        applicable, used by Client, as set forth in the License
                        Agreement or through your Account. Where Client has
                        entered into the License Agreement, except as set forth
                        therein, all License Fees are due and payable to MadTech
                        within thirty (30) days of receipt of MadTech’s invoice.
                        Client acknowledges and agrees that it is subject to all
                        additional payment and billing obligations set forth in
                        (as applicable) the License Agreement or Client’s terms
                        and conditions with MadTech’s partners (e.g., Snowflake)
                        from which Client is receiving access to the Services.
                        If Client fails to pay any License Fees as and when due
                        (i) MadTech shall have the right to suspend Client’s
                        and/or any Client Customer’s access to the Services,
                        until payment is made in full and (ii) MadTech may
                        charge a late fee of one and one-half percent (1½%) per
                        month of, if lower, the maximum rate allowed by law.
                        Client will reimburse MadTech for all reasonable
                        collection expenses, including reasonable attorneys’
                        fees and court costs, for delinquent amounts. If MadTech
                        suspends or terminates access to the Services, MadTech
                        will not be responsible for any damages (including lost
                        revenue, lost profits, lost savings, business
                        interruption or any consequential, exemplary, special,
                        indirect, or punitive damages) incurred as a result of
                        the suspension or termination of the Services. Client is
                        solely responsible for any third-party fees, including
                        costs associated with using bandwidth to send and
                        receive data and/or storage/handling not included in the
                        Services, that Client incurs when accessing and using
                        the Services. In the event a Connection is between
                        Client and a separate client of MadTech, MadTech will
                        provide Client and such separate MadTech client the
                        option to “choose” who pays, or “split” the License
                        Fees.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>7.2 Fee Disputes.</b>
                      <p className="MsoNormal">
                        Users agree to bring up and settle any issues with the
                        billing within thirty (30) days of receipt of MadTech’s
                        invoice. If you have any concerns or objections
                        regarding charges, you agree to raise them with us first
                        and you agree not to cancel or reject any credit card or
                        third-party payment processing charges unless you have
                        made a reasonable attempt at resolving the matter
                        directly with MadTech.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>7.3 Changes to Price Terms.</b>
                      <p className="MsoNormal">
                        MadTech reserves the right to change its pricing terms
                        for the Services at any time, in which case MadTech will
                        notify you in advance of such changes becoming
                        effective. Changes to the pricing terms will not apply
                        retroactively and will only apply for the Services
                        renewals after such changed pricing terms have been
                        communicated to you and/or the general public.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={8} type={1}>
                <li className="MsoNormal">
                  <b>CONFIDENTIAL INFORMATION</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>8.1 Definition.</b>
                      <p className="MsoNormal">
                        The Parties acknowledge that, in the course of
                        performance under these Terms, one Party
                        <span style={{ fontWeight: "bold" }}>
                          (“Disclosing Party”)
                        </span>
                        may intentionally or inadvertently disclose, deliver or
                        permit access to by the other Party
                        <span style={{ fontWeight: "bold" }}>
                          (“Receiving Party”)
                        </span>
                        certain data, materials, methodologies, and information
                        (in written, oral, and/or electronic format) that a
                        reasonable person would understand to be secret,
                        proprietary and/or confidential given the circumstances
                        of the disclosure or the nature of the information being
                        disclosed (collectively
                        <span style={{ fontWeight: "bold" }}>
                          “Confidential Information”
                        </span>
                        ).
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>8.2 Confidentiality Obligations.</b>
                      <p className="MsoNormal">
                        Receiving Party shall hold all Confidential Information
                        of Disclosing Party in the strictest confidence and
                        shall not disclose or provide such Confidential
                        Information to any third party without the express
                        written consent of Disclosing Party in each instance,
                        except that Receiving Party may disclose or provide such
                        Confidential Information to the officers, directors,
                        employees, subcontractors, and suppliers of Receiving
                        Party whose duties justify a need-to-know such
                        Confidential Information; who are notified of their
                        burden of confidentiality; and in the case of those who
                        are not officers, directors or employees of Receiving
                        Party, who have signed a non-disclosure agreement
                        containing restrictions, terms and conditions that are
                        at least as restrictive as those set forth herein. In
                        all events, Receiving Party shall use the same level of
                        care to protect the Confidential Information of
                        Disclosing Party as Receiving Party uses to protect
                        Receiving Party’s own most confidential and sensitive
                        information but not less than reasonable care. Receiving
                        Party shall not make any use whatsoever of Confidential
                        Information of Disclosing Party except such limited use
                        as is required to perform Receiving Party’s obligations
                        under these Terms. To the limited extent reasonably
                        necessary for such permitted use, the foregoing shall
                        include the right to make a reasonable number of copies
                        of such Confidential Information, each of which shall be
                        subject to this section.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>8.3 Exclusions.</b>
                      <p className="MsoNormal">
                        The obligations set forth in this section shall not
                        apply to Confidential Information which Receiving Party
                        can demonstrate by reasonable written evidence: (i) is
                        already, or otherwise becomes, generally known by third
                        parties as a result of no act or omission of Receiving
                        Party; (ii) subsequent to disclosure hereunder is
                        lawfully received from a third party having the right to
                        disseminate the information and without restriction on
                        disclosure; (iii) is generally furnished to others by
                        Disclosing Party without restriction on disclosure; or
                        (iv) is independently developed by Receiving Party with
                        written evidence of such independent development.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>8.4 Compelled Disclosures.</b>
                      <p className="MsoNormal">
                        Notwithstanding the foregoing, Receiving Party may
                        disclose Confidential Information to the limited extent
                        required (i) in order to comply with the order of a
                        court or other governmental body, or as otherwise
                        necessary to comply with applicable law, provided that,
                        to the extent permitted by law, Receiving Party shall
                        first have given written notice to Disclosing Party and
                        a reasonable opportunity to object (with Receiving
                        Party’s assistance) and Receiving Party shall have made
                        a reasonable effort to obtain a protective order; or
                        (ii) to establish Receiving Party’s rights under these
                        Terms, including to make such court filings as it may be
                        required to do.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>8.5 Return of Confidential Information; Ownership.</b>
                      <p className="MsoNormal">
                        Upon the written request of Disclosing Party, Receiving
                        Party shall cease providing access, using and promptly
                        securely destroy all copies of any Confidential
                        Information of Disclosing Party then in Receiving
                        Party’s possession or under Receiving Party’s control,
                        subject to Disclosing Party’s right to the return of
                        original copies of any of its Confidential Information.
                        Upon the written request of Disclosing Party, Receiving
                        Party shall confirm in writing that Receiving Party has
                        complied with the obligations set forth in this
                        paragraph. Notwithstanding the foregoing, Receiving
                        Party is not required to destroy, erase or modify any
                        archival records that it maintains in the normal course
                        of its business, provided that the terms of these Terms
                        shall survive termination and apply to such Confidential
                        Information until it is securely destroyed. Except as
                        expressly provided herein, nothing in these Terms shall
                        be construed to grant Receiving Party any right, title
                        or interest (including any license) in or to
                        Confidential Information of Disclosing Party.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>8.6 Data Processing.</b>
                      <p className="MsoNormal">
                        To the extent MadTech is processing any “personal data”
                        or “personal information” as those terms are defined and
                        understood under applicable laws, the Parties will
                        comply with the terms and conditions of the Data
                        Processing Addendum (
                        <span style={{ fontWeight: "bold" }}>
                          “Data Processing Addendum”
                        </span>
                        ) posted at <Link to={"/data-processing-addendum"}>Data Processing Addendum.</Link>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={9} type={1}>
                <li className="MsoNormal">
                  <b>INTELLECTUAL PROPERTY RIGHTS</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>9.1 Intellectual Property Ownership.</b>
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>(i)</span>
                        All content on the Services (including, for example,
                        text, designs, graphics, logos, icons, images, audio
                        clips, downloads, interfaces, Information, code and
                        software, and the selection and manner of compilation
                        and presentation) (collectively, the “Content”), is
                        owned by MadTech, our content providers, or our
                        licensors (as applicable), and may be protected by
                        copyright, trademark, and other applicable laws.
                        MadTech, our content providers, or our licensors (as
                        applicable) retain full and complete title to and
                        reserve all rights in the Content on the Services,
                        including all associated Intellectual Property Rights.
                        MadTech neither warrants nor represents that your use of
                        Content on the Services will not infringe rights of
                        third parties. “Intellectual Property Rights” means all
                        forms of intellectual property rights and protections
                        and include without limitation all right, title and
                        interest arising under U.S. common and statutory law,
                        and laws of other countries in and to all: (a) patents,
                        inventions, discoveries and ideas, whether or not
                        patentable; (b) trademarks, service marks, trade names,
                        and all other designations of source or origin and the
                        goodwill associated therewith (all of the foregoing in
                        this clause (b) collectively “Trademarks”); (c)
                        copyright rights, works of authorship, software; (d)
                        database rights, rights in trade secrets, know-how,
                        processes, technology, financial information and other
                        proprietary or confidential information; (e) domain
                        names, mask works, moral rights, rights of privacy and
                        publicity; (f) similar or corresponding intellectual
                        property and proprietary rights, and other subject
                        matter of any of the foregoing; (g) tangible embodiments
                        of any of the foregoing; (h) all rights and remedies
                        against infringement, misappropriation, or other
                        violation (including the right to sue for past, present
                        and future infringements of any of the foregoing); and
                        (i) licenses in, to and under any of the foregoing.
                      </p>

                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>(ii)</span>
                        You may access the Services only for your permitted use
                        under these Terms, and you may not modify or delete any
                        copyright, trademark, or other proprietary notice
                        relating to any Content you access. Your access to and
                        use of the Services does not grant you any license or
                        right to use any trademark, logo, or service mark
                        displayed on the Services. You agree not to display or
                        use in any manner the MadTech marks without MadTech's
                        advance written permission.
                      </p>

                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>(iii)</span>
                        All software used in connection with the Services is the
                        property of MadTech or our licensors and protected by
                        United States and international copyright laws, and
                        subject to separate license terms, in which case those
                        license terms will govern such software. You agree not
                        to reproduce, duplicate, copy, sell, resell, or exploit
                        any portion of the Services, use of the Services or
                        access to the Services, or any contact on the Services
                        through which the Services are provided, without express
                        written permission by us.
                      </p>

                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>(iv)</span>
                        All rights not expressly granted herein are reserved by
                        MadTech, our affiliates, and licensors. Without limiting
                        the generality of the foregoing, except for the license
                        expressly granted in these Terms, MadTech retains all
                        right, title, and interest in and to the Platform and
                        the Documentation, including without limitation all
                        Intellectual Property Rights therein. You agree to abide
                        by all additional restrictions displayed on the
                        Services, and as they may be updated from time to time.
                      </p>
                    </li>
                  </ol>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <b>9.2. FEEDBACK; RESIDUAL KNOWLEDGE.</b>
                      <p className="MsoNormal">
                        If Client or any of its Client Users provides MadTech
                        with any suggestions, recommendations, or other feedback
                        relating to MadTech’s current or future products or
                        services (
                        <span style={{ fontWeight: "bold" }}>“Feedback”</span>
                        ), MadTech shall have the right to use the Feedback in
                        any manner, including but not limited to future
                        enhancements and modifications to the Services. Client
                        hereby grants to MadTech and its assigns a perpetual,
                        worldwide, fully transferable, sublicensable, fully
                        paid-up, irrevocable, royalty-free license to use,
                        reproduce, modify, create derivative works from,
                        distribute, and display the Feedback in any manner and
                        for any purpose, in any media, software, or technology
                        of any kind now existing or developed in the future,
                        without any obligation to provide attribution or
                        compensation to Client or any third party. By providing
                        us Feedback you represent and warrant (i) that you have
                        the right to disclose the Feedback, (ii) that the
                        Feedback does not violate the rights of any other person
                        or entity, including, but not limited to, Intellectual
                        Property Rights, and (iii) that your Feedback does not
                        contain the confidential or proprietary information of
                        any third party or parties. In addition, MadTech shall
                        be free to reuse all general knowledge, experience,
                        know-how, works and technologies (including ideas,
                        concepts, processes and techniques) related to or
                        acquired during provision of the Services.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={10} type={1}>
                <li className="MsoNormal">
                  <b> WARRANTIES; DISCLAIMER OF WARRANTIES</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          10.1 Mutual Warranties.
                        </span>
                        Each Party represents and warrants that: (i) it has all
                        requisite corporate power and authority to execute,
                        deliver and perform its obligations under these Terms
                        and the License Agreement; (ii) the execution, delivery
                        and performance of these Terms and the License Agreement
                        (a) has been duly authorized by such Party, and (b) will
                        not conflict with, result in a breach of or constitute a
                        default under any other agreement to which such Party is
                        a party or by which such Party is bound; and (iii) it is
                        duly licensed, authorized or qualified to do business
                        and is in good standing in every jurisdiction in which a
                        license, authorization or qualification is required for
                        the ownership or leasing of its assets or the
                        transaction of business of the character transacted by
                        it, except where the failure to be so licensed,
                        authorized or qualified would not have a material
                        adverse effect on such Party’s ability to fulfill its
                        obligations under these Terms and the License Agreement.
                      </p>
                    </li>
                  </ol>

                  <ol style={{ marginTop: "0in" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          10.2 MadTech Warranties.
                        </span>
                        MadTech warrants to Client that the Platform will
                        perform materially in accordance with the provisions of
                        the applicable MadTech-provided user manuals, training
                        materials, descriptions and specifications, technical
                        manuals, supporting materials and other information
                        related to the MadConnect Platform to the extent
                        necessary to practice the license grants set forth
                        herein, whether distributed or provided to Client in
                        print, electronic, CD-ROM, video or any other format
                        (collectively, the “Documentation”). MadTech’s sole
                        liability and Client’s exclusive right and remedy for a
                        breach of the foregoing warranty is for MadTech to
                        correct or re-perform the nonconforming Platform.
                      </p>
                    </li>
                  </ol>

                  <ol style={{ marginTop: "0in" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          10.3 Client Warranties.
                        </span>
                        Client warrants that Client owns or has obtained all
                        necessary rights, title and interest, and obtained all
                        necessary consents, to transfer any Client data to the
                        Third Party Platforms via the Service.
                      </p>
                    </li>
                  </ol>

                  <ol style={{ marginTop: "0in" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          10.4 Disclaimer.
                        </span>
                        You expressly agree that the use of, or inability to
                        use, the Services is at your sole risk. TO THE EXTENT
                        PERMITTED BY APPLICABLE LAW, MADTECH AND ITS OFFICERS,
                        EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS,
                        SUBSIDIARIES, AFFILIATES, AGENTS, AND LICENSORS DISCLAIM
                        ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS OF ANY
                        KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE,
                        INCLUDING, WITHOUT LIMITATION, THOSE RELATED TO
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                        NON-INFRINGEMENT, AND THOSE ARISING OUT OF COURSE OF
                        DEALING OR USAGE OF TRADE. The Services are provided on
                        an “as is” and “as available” basis. TO THE EXTENT
                        PERMITTED BY APPLICABLE LAW, MADTECH AND ITS AFFILIATES
                        MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY
                        OR COMPLETENESS OF CONTENT AVAILABLE ON OR THROUGH THE
                        SERVICES, OR THE CONTENT OF ANY THIRD-PARTY WEBSITES OR
                        SERVICES LINKED TO OR INTEGRATED WITH OUR SERVICES. WE
                        DO NOT REPRESENT OR WARRANT THAT (i) YOUR USE OF OUR
                        SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                        ERROR-FREE, (ii) ANY ERRORS IN THE SERVICES WILL BE
                        CORRECTED, (iii) THE QUALITY OF THE SERVICES,
                        INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY
                        YOU WILL MEET YOUR EXPECTATIONS, (iv) THE SERVICES WILL
                        BE FREE OF ANY WORMS OR VIRUSES OR ANY CODE OF A
                        MALICIOUS AND/ OR DESTRUCTIVE NATURE, OR (v) THE RESULTS
                        THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL
                        BE ACCURATE OR RELIABLE. MADTECH AND ITS AFFILIATES WILL
                        HAVE NO LIABILITY FOR ANY: (i) ERRORS, MISTAKES, OR
                        INACCURACIES OF CONTENT; (ii) PERSONAL INJURY OR
                        PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF
                        THE SERVICES OR CONSUMPTION OF ANY CONTENT; (iii) ANY
                        UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR OF ANY
                        PERSONAL INFORMATION OR CLIENT DATA; (iv) ANY
                        INTERRUPTION OF TRANSMISSION TO OR FROM THE SERVICES;
                        (v) ANY BUGS, VIRUSES, TROJAN HORSES OR THE LIKE WHICH
                        MAY BE TRANSMITTED ON OR THROUGH THE SERVICES; (vi) ANY
                        LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
                        USE OF ANY CONTENT POSTED OR SHARED THROUGH THE
                        SERVICES; OR (vii) LOSS OR DAMAGE CAUSED BY ANOTHER
                        USER’S VIOLATION OF THESE TERMS.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={11} type={1}>
                <li className="MsoNormal">
                  <b> Indemnification</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          11.1 Indemnification by Client.
                        </span>
                      </p>
                      You agree to indemnify, defend and hold MadTech and its
                      parent, subsidiaries, affiliates, partners, officers,
                      directors, contractors, licensors, service providers,
                      subcontractors, suppliers, interns, agents, and employees,
                      harmless from and against any and all losses, claims,
                      liabilities, damages, judgments, actions, proceedings,
                      investigations (whether formal or informal), demands or
                      expenses (including reasonable attorneys’ fees), or
                      threats thereof, due to, arising out of or relating to (i)
                      the use by Client or any Client Customer of the Platform,
                      including any claim by any Client Customer under its
                      Client Customer Agreement; however, Client will not be
                      liable for any losses or damages if and to the extent
                      caused by a defect in the operation of the Platform; (ii)
                      Client’s or any Client Customer’s connection with, use of,
                      or transfer of data to or from, any Third Party Platform,
                      or the failure of Client, any Client Customer, or any
                      Third Party Platform to encrypt data that is transferred
                      via the Platform; (iii) any unauthorized access to or use
                      of by Client or any Client Customer of any Client API Key
                      or any Client Customer API Key; (iv) any breach or
                      violation by Client of these Terms or the documents
                      incorporated by reference or hyperlink; (v) any breach by
                      Client or a Client Customer of a Client Customer Agreement
                      or a Third Party Platform Agreement; (vi) Client’s or any
                      Client Customer’s violation or alleged violation of any
                      applicable federal, state, international or local law and
                      any and all regulations, rules or ordinances; (vii) the
                      fraud, gross negligence or willful misconduct of or by
                      Client or any Client Customer; or (viii) the use by
                      MadTech of any of the Client Marks on a White Label
                      Platform, including any claim that such use infringes or
                      violates any intellectual property or other legal right of
                      any person or entity.
                    </li>
                  </ol>

                  <ol style={{ marginTop: "0in" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          11.2 Indemnification by MadTech.
                        </span>
                      </p>
                      MadTech shall indemnify, defend and hold harmless Client,
                      its affiliates, directors, officers, shareholders, agents,
                      contractors and employees, from and against any and all
                      losses, damages, actions, proceedings, costs and expenses
                      (including reasonable attorneys’ fees and costs) arising
                      from, relating to or resulting from (i) the misuse of the
                      MadConnect Platform by any other MadTech client or any
                      customer of such MadTech client; or (ii) the co-mingling
                      of Client’s or any Client Customer’s data with the data of
                      any other MadTech client.
                    </li>
                  </ol>

                  <ol style={{ marginTop: "0in" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          11.3 Indemnification Procedures.
                        </span>
                      </p>
                      Upon commencement of any indemnified claim, the
                      indemnified Party shall give the indemnifying Party notice
                      thereof as promptly as practicable, and the indemnifying
                      Party shall have the sole ability to defend and settle the
                      third-party claim; provided, that the indemnifying Party
                      shall not settle the claim in any way other than the
                      payment of money (provided that customary confidentiality
                      and non-defamation provisions applicable to indemnitees
                      may be agreed). The indemnified Party shall cooperate, at
                      the cost of the indemnifying Party, in all reasonable
                      respects with the indemnifying Party and its attorneys in
                      the investigation, trial and defense of such claim and any
                      appeal arising therefrom; provided, however, that the
                      indemnified Party may, at its own cost and expense,
                      participate, through its attorneys or otherwise, in such
                      investigation, trial and defense of such claim and any
                      appeal arising therefrom. If the indemnifying Party does
                      not assume full control over the defense of a claim
                      subject to such defense as provided in this Section, the
                      indemnified Party shall have the right to defend the claim
                      in such manner as it may deem appropriate, at the cost and
                      expense of the indemnifying Party.
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>
              <ol style={{ "margin-top": "0in" }} start={12} type={1}>
                <li className="MsoNormal">
                  <b>LIMITATIONS OF LIABILITY</b>
                </li>
              </ol>
              <p className="MsoNormal">
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL MADTECH
                AND ITS AFFILIATES, OFFICERS, DIRECTORS, AGENTS, CONTRACTORS,
                REPRESENTATIVES, INTERNS, SUPPLIERS, SERVICE PROVIDERS, OR
                LICENSORS BE LIABLE FOR ANY PUNITIVE, INDIRECT, INCIDENTAL,
                SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING FROM THESE
                TERMS OR THE SERVICES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
                LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE
                LOSSES, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF
                MADTECH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, UNDER NO
                CIRCUMSTANCES WILL THE MAXIMUM TOTAL LIABILITY OF MADTECH AND
                ITS AFFILIATES FOR ANY CLAIMS ARISING OUT OF OR IN ANY WAY
                RELATED TO THESE TERMS AND/OR CLIENT’S ACCESS OR USE OF THE
                SERVICE, EXCEED THE AGGREGATE AMOUNT, IF ANY, THAT CLIENT HAS
                PAID TO MADTECH FOR THE SERVICES DURING THE TWELVE (12) MONTH
                PERIOD IMMEDIATELY PRIOR TO THE DATE THE CLAIM FIRST AROSE,
                REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT
                (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS), TORT, NEGLIGENCE
                OR OTHERWISE.
              </p>

              <p className="MsoNormal">&nbsp;</p>
              <ol style={{ "margin-top": "0in" }} start={13} type={1}>
                <li className="MsoNormal">
                  <b>GOVERNING LAW & JURISDICTION</b>
                </li>
              </ol>
              <p className="MsoNormal">
                Client and MadTech agree that all matters arising from these
                Terms or relating to the use and operation of the Services will
                be governed by the laws of the State of Connecticut, without
                regard to its conflicts of laws principles. Client agrees that
                all such matters will be litigated in the state courts of
                Connecticut or the United States District Court of the District
                of Connecticut (and appellate courts therefrom). Client consents
                to the personal jurisdiction of such courts, stipulates to the
                fairness and convenience of proceeding in such courts, and
                covenants not to assert any objections to proceeding in such
                courts.
              </p>

              <p className="MsoNormal">&nbsp;</p>

              <ol style={{ marginTop: "0in" }} start={14} type={1}>
                <li className="MsoNormal">
                  <b>GENERAL</b>

                  <ol style={{ marginTop: "0in", paddingLeft: "20px" }}>
                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          14.1 Entire Agreement.
                        </span>
                        These Terms, any License Agreement, our Data Processing
                        Addendum, and any other policies or operating rules
                        posted by us on the Services or in respect to the
                        Services constitute the entire and exclusive agreement
                        and understanding between you and MadTech related to the
                        Services, and supersedes any prior or contemporaneous
                        agreements, communications, and proposals, whether oral
                        or written, between you and us (including, but not
                        limited to, any prior versions of these Terms). Any
                        ambiguities in the interpretation of these Terms shall
                        not be construed against the drafting party.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          14.2 Changes to the Service; Changes to the Terms.
                        </span>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "0.5in", textIndent: "-.25in" }}
                        >
                          <span
                            style={{ fontSize: "10.0pt", lineHeight: "107%" }}
                          >
                            (i)
                            <span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                          </span>
                          Changes to the Service. We reserve the right to modify
                          or upgrade the Services for any reason, without
                          notice, at any time, which may include upgrades, bug
                          fixes, patches, other error corrections, and/or new
                          features (collectively, including related
                          documentation, "Updates"). Additionally, Updates may
                          also modify or delete in their entirety certain
                          features and functionality. You agree that MadTech has
                          no obligation and shall not be liable to you or to any
                          third party to provide any Updates or to continue to
                          provide or enable any particular features or
                          functionality. You further agree that all Updates will
                          be deemed part of the Services and be subject to all
                          terms and conditions of these Terms.
                        </p>

                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "0.5in", textIndent: "-.25in" }}
                        >
                          <span
                            style={{ fontSize: "10.0pt", lineHeight: "107%" }}
                          >
                            (ii)
                            <span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                          </span>
                          Changes to the Terms. MadTech reserves the right, at
                          our sole discretion, to update, change, amend, modify
                          or replace any part of these Terms by posting updates
                          and changes on this website. We may elect to notify
                          you of such changes by mail, email, posting of
                          modified Terms, or some other similar manner. However,
                          it is your responsibility to check the Services
                          regularly for changes to these Terms. At all times,
                          the most current version of these Terms will be in
                          effect and each updated, changed, amended, modified,
                          or replacement Terms supersedes any prior Terms. Your
                          continued use of or access to the Services following
                          the posting of any changes to these Terms constitutes
                          acceptance of those changes.
                        </p>
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          14.3 Future Functionality.
                        </span>
                        You agree that your purchases are not contingent on the
                        delivery of any future functionality or features, or
                        dependent on any oral or written public comments made by
                        MadTech regarding future functionality or features.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          14.4 Severability.
                        </span>
                        In the event that any provision of these Terms shall, in
                        whole or in part, be determined to be invalid,
                        unenforceable or void for any reason, such determination
                        shall affect only the portion of such provision
                        determined to be invalid, unenforceable or void, and
                        shall not affect in any way the remainder of such
                        provision or any other provision of these Terms.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>14.5 Waiver.</span>
                        The failure of MadTech to exercise or enforce any right
                        or provision of these Terms shall not impair such right
                        or be constituted as a waiver of any such right or
                        remedy. Any single or partial exercise of a right or
                        remedy by us shall not preclude further exercise of any
                        right or remedy by us. No waiver by us shall be valid
                        unless in writing signed by us.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          14.6 Survival.
                        </span>
                        Upon termination, all provisions of these Terms, which,
                        by their nature, should survive termination, shall
                        survive termination, including, without limitation,
                        confidentiality, ownership provisions, warranty
                        disclaimers, indemnification, and limitations of
                        liability.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          14.7 Assignment.
                        </span>
                        You may not assign these Terms to any other party. We
                        may assign these Terms or delegate any or all of our
                        rights and responsibilities under these Terms to any
                        third parties, without notice to you. Subject to the
                        foregoing limitation, these Terms will mutually benefit
                        and be binding upon the Parties and their respective
                        successors and assigns. There are no third-party
                        beneficiaries of these Terms.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          14.8 Headings.
                        </span>
                        The headings used in these Terms are included for
                        convenience only and will not limit or otherwise affect
                        these Terms.
                      </p>
                    </li>

                    <li
                      className="MsoNormal"
                      style={{ marginBottom: "6.0pt", listStyleType: "none" }}
                    >
                      <p className="MsoNormal">
                        <span style={{ fontWeight: "bold" }}>
                          14.9 Contact Information.
                        </span>
                        Questions about these Terms should be sent to:{" "}
                        <Link to="mailto:partnerships@madtech.io">partnerships@madtech.io</Link> .
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>

              <p className="MsoNormal">&nbsp;</p>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default CommanTermsCondition;
