import styled, { createGlobalStyle } from 'styled-components';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
    
export const SqlCommandSection = styled(SyntaxHighlighter)`
    span {
        font-family: inherit;
    }
    code {
        white-space: pre-wrap !important;
    }
    padding: 2em 1em !important;
`;