import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Modal,
  OutlinedInput,
  Typography,
  Menu,
  Fade,
  Chip,
  InputAdornment,
  Tooltip,
  CircularProgress
} from "@mui/material";

import selectPlus from "../../assets/SelectPlus.svg";
import Cancel from "../../assets/cancelWhite.svg";
import Info from "../../assets/infoWhite.svg";
import InfoIcon from '../../assets/NewInfo.svg'
import Search from "../../assets/search (1).svg";
import { color } from "../../utils/Colors";
import { InfoModalPoints } from "../../utils/Strings";

const CommonSelect = ({
  handleClick,
  data,
  setData,
  anchorEl,
  open,
  handleClose,
  inputValue,
  setInputValue,
  setOptions,
  options,
  title,
  setShowDestination,
  setShowSources = () => { },
  isSmallCreateConnection,
  source,
  destination,
  configurationLoading,
  connectionId,
  disabled,
  selectType,
  setDestination,
  setDestinationConfiguration,
  setDestinationConfig
}) => {
  const [infoOpen, setInfoOpen] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState(options || []);

  useEffect(() => {
    const newFilteredOptions = inputValue ? options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase())): options;
    setFilteredOptions(newFilteredOptions);
  }, [inputValue, options])

  const style = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px 8px 8px 8px",
      bgcolor: "#F8F8FE",
      width: "36.25rem",
      boxShadow: 24,
      padding: "0px 0px 20px 0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      // alignItems: "center",
      "&:focus": {
        outline: "none",
      },
    }),
    []
  );
  return (
    <>
      <Box
        sx={{
          cursor: (connectionId === null || connectionId === undefined) && !disabled ? "pointer" : "not-allowed",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: 2.5,
          border: `1px solid #EAEDF6`,
          height: "100px",
        }}
      >
        {/* <Box onClick={handleClick} sx={{
                display: "flex", cursor: "pointer", backgroundColor: "#fff",
                flexDirection: "column", p: "0.5rem", borderRadius: 2, border: "1px solid black"
            }}>
                </Box> */}
        {data ? (
          <div
            onClick={(connectionId === null || connectionId === undefined) && !disabled ? handleClick : () => { }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              width: "100%",
              overflow: "hidden",
              paddingRight: "10px"
            }}
          >
            <div style={{ width: "33%" }}>

            </div>
            <div style={{ width: "55%", display: "flex", justifyContent: "center" }}>
              {configurationLoading ? <><CircularProgress style={{ color: "#3646ac" }} size={25} thickness={8}></CircularProgress></> : <img
                alt=""
                src={data.img}
                // src="http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/s3.png"
                style={{
                  maxWidth: "50%",
                  maxHeight: "50%",
                  objectFit: "contain",
                }}
              />}
            </div>
            <div style={
              {
                width: "33%",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                paddingTop: "7px",
                paddingBottom: "5px"
              }
            }>
              <div style={{ display: "flex", justifyContent: "end" }} >
                <img
                  alt=""
                  onClick={(e) => {
                    if (!disabled) {
                      setInfoOpen(true)
                      e.stopPropagation()
                    }
                  }}
                  src={InfoIcon}
                  style={{
                    width: "24px",
                    height: "24px",
                     cursor: "pointer"
                  }}
                ></img>
              </div>
              <div>
                <Tooltip title={<span style={{
                  textTransform: "capitalize",
                  fontSize: "1rem"
                }}>{data.dataType ? data?.dataType : data?.dataTypes[0] ? data?.dataTypes[0] : ""}</span>} placeholder="top">
                  <div style={{
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: 'end'
                  }}>
                    {data?.dataType ? data?.dataType : data?.dataTypes[0] ? data?.dataTypes[0] : ""}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        ) : (
          <Box
            onClick={handleClick}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: !disabled ? "pointer" : "not-allowed",
              padding: "22px",
              gap: "14px",
              width: "100%",
              opacity: !disabled ? 1 : 0.5
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "2.5rem",
                borderRadius: 5,
              }}
            >
              <img
                alt=""
                src={selectPlus}
                style={{ width: "3.5rem", height: "3.5rem", }}
              ></img>
            </Box>
            <Typography
              sx={{
                color: color.themeColor,
                fontSize: isSmallCreateConnection ? "16px" : "1.25rem",
                fontWeight: 500,
                fontFamily: "Helvetica Neue",
              }}
            >
              {title}
              {(!source || !destination) && (
                <Typography
                  sx={{
                    mt: "0.1rem",
                    color: "#666666",
                    fontSize: isSmallCreateConnection ? "12px" : "1rem",
                    fontWeight: 500,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {/* {source ? source.description : 'Data input platform'} */}
                  {selectType === "source" ? "Data input platform" : "Data output platform"}
                </Typography>
              )}
            </Typography>
          </Box>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
          PaperProps={{
            style: {
              width: "31%",
              borderRadius: "10px",
              paddingTop: "15px",
              marginTop: "7px",
            },
          }}
        >
          <OutlinedInput
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            sx={{
              "& fieldset": {
                borderColor: "#EAEDF6",
              },
            }}
            style={{
              width: "92.5%",
              marginBottom: 10,
              margin: "auto",
              height: "2.5rem",
              borderRadius: "8px",
              background: "#EAEDF6",
              display: "flex",
              justifyContent: "center",
            }}
            startAdornment={
              <InputAdornment position="start">
                <img src={Search} alt="Search icon" style={{ width: 22, height: 22 }} />
              </InputAdornment>
            }
          />
          <Box
            sx={{
              height: 235,
              marginTop: "10px",
              overflowY: "scroll",
              mb: 0,
            }}
          >
            {filteredOptions.length ? (
              filteredOptions.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    justifyContent: "space-between",
                    padding: "11px 18px",
                    cursor: item?.dataTypeId === data?.dataTypeId ? "not-allowed" : "pointer",
                    alignItems: "center",
                    opacity: item?.dataTypeId === data?.dataTypeId ? 0.5 : 1,
                    "&:hover": {
                      backgroundColor: item?.dataTypeId === data?.dataTypeId ? "" : "#F3F4FA",
                    },
                  }}
                  onClick={() => {
                    if (item?.dataTypeId !== data?.dataTypeId) {
                      handleClose();
                      const dataType = item?.dataTypes.length > 0 ? item?.dataTypes[0] : "";
                      setData({ ...item, dataType });
                      setShowDestination(true);
                      setShowSources(true);
                      if (selectType === "source") {
                        setDestination(null)
                        setDestinationConfiguration([]);
                        setDestinationConfig({});
                        return
                      }
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        ml: "0.5rem",
                        color: "#000000",
                        fontSize: "1rem",
                        fontWeight: 500,
                      }}
                    >
                      {item?.label}
                    </Typography>
                    {item?.dataTypes?.map((dataType) => (
                      <Box sx={{ display: "flex", gap: "0.5rem" }} key={dataType}>
                        <Chip
                          label={dataType ? dataType : ""}
                          size="small"
                          sx={{
                            textTransform: "capitalize",
                            borderRadius: "10px",
                            borderColor: color.themeColor,
                            "& .MuiChip-label": {
                              fontSize: "0.7rem",
                              color: color.themeBlack,
                              fontWeight: 500,
                            },
                          }}
                          variant="filled"
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={80}
              >
                <Typography
                  sx={{
                    color: "#bdbdbd",
                    fontSize: "1.5rem",
                    fontWeight: 500,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  No data found
                </Typography>
              </Box>
            )}
          </Box>
        </Menu>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        closeAfterTransition
        // slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={infoOpen}
        >
          <Box sx={{ ...style, background: "#fff", borderRadius: "20px", }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: 60,
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                background: "#1C2B47"
              }}
            >
              <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                p: 2
              }}>
                <Box>
                  <img
                    alt=""
                    src={Info}
                    style={{ width: 20, height: 20, color: "#fff", cursor: "pointer" }}
                  ></img>
                  <span style={{ color: "white", marginLeft: "10px", marginTop: "2px" }}>Information</span>
                </Box>
                <Box onClick={() => setInfoOpen(false)}>
                  <img
                    alt=""
                    src={Cancel}
                    style={{ width: 20, height: 20, color: "#fff", cursor: "pointer" }}
                  ></img>
                </Box>
              </Box>
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Helvetica Neue",
                  fontSize: "16px",
                  textAlign: "start",
                  color: "#25262D",
                  width: "100%",
                  p: 1,
                }}
              >
                {data?.label}
              </Typography>
              <ul>
                {InfoModalPoints?.[data?.label]?.map((e, i) =>

                  // <Typography
                  //   sx={{
                  //     fontWeight: 400,
                  //     fontFamily: "Helvetica Neue",
                  //     fontSize: "14px",
                  //     textAlign: "start",
                  //     color: "#66696A",
                  //     width: "100%",
                  //     p: 1,
                  //   }}
                  //   key={i}
                  // >
                  <li style={{ fontFamily: "helvetica neue", color: "#66696A", fontSize: "14px" }}> {e}</li>

                  // </Typography>
                )}
              </ul>
              {/* <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Helvetica Neue",
                  fontSize: "16px",
                  textAlign: "start",
                  color: "#25262D",
                  width: "100%",
                  p: 1,
                }}
              >
                Lorem ipsum
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  textAlign: "start",
                  color: "#66696A",
                  width: "100%",
                  p: 1,
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Pellentesque auctor sollicitudin et sollicitudin nisl ac consectetur interdum.
              </Typography><Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Helvetica Neue",
                  fontSize: "16px",
                  textAlign: "start",
                  color: "#25262D",
                  width: "100%",
                  p: 1,
                }}
              >
                Lorem ipsum
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  textAlign: "start",
                  color: "#66696A",
                  width: "100%",
                  p: 1,
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Pellentesque auctor sollicitudin et sollicitudin nisl ac consectetur interdum.
              </Typography><Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Helvetica Neue",
                  fontSize: "16px",
                  textAlign: "start",
                  color: "#25262D",
                  width: "100%",
                  p: 1,
                }}
              >
                Lorem ipsum
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  textAlign: "start",
                  color: "#66696A",
                  width: "100%",
                  p: 1,
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Pellentesque auctor sollicitudin et sollicitudin nisl ac consectetur interdum.
              </Typography> */}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>

  );
};

export default CommonSelect;

// <Popper
//     open={open}
//     anchorEl={anchorEl}
//     role={undefined}
//     placement="bottom-start"
//     transition
//     disablePortal
// >
//     {({ TransitionProps, placement }) => (
//         <Grow
//             {...TransitionProps}
//             style={{
//                 transformOrigin:
//                     placement === 'bottom-start' ? 'left top' : 'left bottom',
//             }}
//         >
//             <Paper>
//                 {/* <ClickAwayListener onClickAway={handleClose}> */}
//                 {options.map((item, idx) =>
//                     <Box key={idx} sx={{
//                         display: "flex", flexDirection: "row",
//                         padding: '8px 12px', background: "#fff",
//                         cursor: "pointer", alignItems: "center",
//                         '&:hover': {
//                             backgroundColor: '#F3F4FA',
//                             opacity: [0.9, 0.8, 0.7],
//                         },
//                     }} onClick={() => {
//                         handleClose()
//                         setData(item)
//                     }}>
//                         <Box sx={{
//                             display: "flex", justifyContent: "center", alignItems: "center",
//                             width: 28, height: 28, borderRadius: 5, border: "2px solid #eeeeee"
//                         }}>
//                             <img alt='' src={item?.img} style={{ width: "1.5rem", height: 24 }}></img>
//                             {/* <img alt='' src={require(`../../assets/${item?.label}.svg`)} style={{ width: "1.25rem", height: "1.25rem" }}></img> */}
//                         </Box>
//                         <Typography sx={{ ml: "0.5rem", color: "#000000", fontSize: "0.875rem", fontWeight: 500 }}>
//                             {item?.label}
//                         </Typography>
//                     </Box>
//                 )}
//                 {/* </ClickAwayListener> */}
//             </Paper>
//         </Grow>
//     )}
// </Popper>
