import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { DateRangePicker } from "react-dates";
import 'react-dates/initialize';
import moment from "moment";
import momentTz from 'moment-timezone';

import AppLayout from "../../components/AppLayout/AppLayout";
import noConfigImg from "../../assets/Illustrations.svg";
import DashboardSingleConnector from "../../components/Plans/DashboardSingleConnector";
import {
  useDeleteConnectionMutation,
  useFilterAnalyticsMutation,
  useGetConnectionsQuery,
  useGetDashboardforParamsConnectionsMutation,
} from "../../services/connectionsQuery";
import upArrowImg from "../../assets/GreenArrowUp.svg";
import downArrowImg from "../../assets/RedArrowDown.svg";
import { madTechState, setLoggedin } from "../../features/madTechSlice";
import CommonDatePicker from "../../components/CommonDatePicker/CommonDatePicker";
import "./Dashboard.css";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";
import { ConnecionWrapper, ConnectionHeading, Heading, NoDataAailable, NoDataAailableHeading, VolumnContainer, VolumnHeading, Wrapper } from "./Dashboard.styles";
import DashboardCard from "./DashboardCard";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";

const DashBoard = ({ setStep }) => {
  const { selectedAccount } = useSelector(madTechState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("USER"));
  const [connectors, setConnectors] = useState([]);
  const [timeLineTxt, setTimeLineTxt] = useState("Year to date");
  const [timemilliseconds, setTimeMilliseconds] = useState(15778800);
  const [hideAvg, setHideAvg] = useState(false);
  const [islogout, setIslogout] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({
    averageDailyVolume: 0,
    averageMonthlyVolume: 0,
    connections: 0,
    currentMonthlyVolume: 0,
    totalVolume: 0,
  });

  const [isCustom, setIsCustom] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusInput] = useState(null);


  const [
    filterAnalytics,
    { data: filterData, isLoading: filterLoading, error: filterError },
  ] = useFilterAnalyticsMutation();

  const [getconnection, {
    data: connectionData,
    error: connectionError,
  }] = useGetDashboardforParamsConnectionsMutation();

  useEffect(() => {
    getconnection()
  }, [])


  const [
    deleteConnection,
    { data: deleteConnectionData, error: deleteConnectionError },
  ] = useDeleteConnectionMutation();

  const deleteConnector = useCallback(
    (id, idx) => {
      deleteConnection({
        id,
      });
      let newConnectors = [...connectors];
      newConnectors.splice(idx, 1);
      setConnectors(newConnectors);
      // setConnectors(connectors?.filter(item => item.id !== id))
    },
    [useDeleteConnectionMutation, connectors]
  );

  useEffect(() => {
    if (connectionData !== undefined && connectionData?.response) {
      // console.log('loogedin user connections', connectionData);
      const allConnections = connectionData?.response?.map((item) => {
        const source = {
          value: item?.src?.name,
          label: item?.src?.name,
          img: item?.src?.logo,
          id: item?.src?.id,
          description: item?.src?.description,
          dataType: item?.src?.dataType,
        };
        const destination = {
          value: item?.dest?.name,
          label: item?.dest?.name,
          img: item?.dest?.logo,
          id: item?.dest?.id,
          description: item?.dest?.description,
          dataType: item?.dest?.dataType,
        };
        return {
          id: item?.id,
          source,
          destination,
          usedVolume: item.usedVolume,
          enabled: item.enabled,
          status: item.status,
          deleted: item.deleted,
          dataType: item.dataType
        };
      });
      setConnectors(allConnections);
    }

    if (connectionError !== undefined) {
      console.log("connectionError", connectionError);
      if (connectionError?.statusCode === 403) {
        navigate("signin");
      }
    }
  }, [connectionData, connectionError]);

  const getAnalytics = useCallback(() => {
    let sDate = parseInt(Date.now() / 1000 - timemilliseconds);
    let eDate = parseInt(Date.now() / 1000);
    const timezoneOffset = momentTz.tz.zone(momentTz.tz.guess()).utcOffset(new Date());
    if (timeLineTxt === "Year to date") {
      // Get current date
      const today = new Date();
      // Get current year
      const year = today.getFullYear();
      // Get first day of year
      const yearFirstDay = new Date(year, 0, 1);
      let milliseconds = (today.getTime() - yearFirstDay.getTime()) / 1000;
      sDate = parseInt(Date.now() / 1000 - milliseconds);
    }

    filterAnalytics({
      endDate: eDate,
      startDate: sDate,
      status: timeLineTxt,
      offset: timezoneOffset
    });
  }, [
    useFilterAnalyticsMutation,
    timemilliseconds,
    timeLineTxt,
  ]);

  const getAnalytics1 = useCallback(
    (startDate, endDate) => {
      const timezoneOffset = momentTz.tz.zone(momentTz.tz.guess()).offset(new Date());
      filterAnalytics({
        endDate: parseInt(endDate / 1000),
        startDate: parseInt(startDate / 1000),
        status: timeLineTxt,
        offset: timezoneOffset
      });
    },
    [useFilterAnalyticsMutation, timemilliseconds, timeLineTxt]
  );

  useEffect(() => {
    if (timemilliseconds) {
      getAnalytics();
    }
  }, [timemilliseconds]);

  useEffect(() => {
    if (
      filterData !== undefined &&
      filterData?.statusCode === 200 &&
      filterData?.response
    ) {
      // console.log('filterData', filterData);
      setGraphData(filterData?.response.graph);
      setAnalyticsData(filterData?.response.analytics);
      setHideAvg(filterData?.response.hideAvg);
    }
    if (filterError !== undefined) {
      console.log("filterError", filterError);
    }
  }, [filterData, filterError]);

  return (
    <AppLayout>
      <Box sx={{ px: "1.5rem" }}>
        {/* heading and date */}
        <Wrapper>
          <SBreadCrumb mainPathName="Dashboard" mainPath="dashboard" />
          {isCustom && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                flex: "auto",
                mr: "1rem",
              }}
            >
              <DateRangePicker
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="startDate" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="endDate" // PropTypes.string.isRequired,
                numberOfMonths={1}
                // isOutsideRange={() => false}
                isOutsideRange={(day) => {
                  return moment()?.diff(day) < 0;
                }}
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                  if (startDate !== null && endDate !== null) {
                    getAnalytics1(startDate, endDate);
                  }
                }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => setFocusInput(focusedInput)} // PropTypes.func.isRequired,
              />

            </Box>
          )}

          <CommonDatePicker
            timeLineTxt={timeLineTxt}
            setTimeLineTxt={setTimeLineTxt}
            setTimeMilliseconds={setTimeMilliseconds}
            getAnalytics={getAnalytics}
            setIsCustom={setIsCustom}
          />
        </Wrapper>
        {/* connection,Average daily volume ..... container  */}
        <DashboardCard
          analyticsData={analyticsData}
          timeLineTxt={timeLineTxt}
          upArrowImg={upArrowImg}
          downArrowImg={downArrowImg}
          hideAvg={hideAvg} />
        {/* Volumn container */}
        <VolumnContainer  >
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
            <VolumnHeading> Volume  </VolumnHeading>
          </Box>

          <Box width="100%" height="100%">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={1100}
                height={400}
                data={graphData}
                margin={{
                  top: 15,
                  right: 30,
                  left: graphData.length > 0 ? 30 : 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" fontSize="12px" />
                <YAxis
                  dataKey="volume"
                  fontSize="12px"
                  tickFormatter={(tick) => {
                    return tick.toLocaleString();
                  }}
                />
                <Tooltip
                  formatter={(value) => 
                    new Intl.NumberFormat("en").format(value)
                  }
                    
                  contentStyle={{
                    background: 'white',
                    width: '15rem', // Set a max width for the tooltip
                    whiteSpace: 'normal', // Allow content to wrap to the next line
                    wordBreak: 'break-word', // Break long words to avoid overflow
                  }}

                />
                {graphData?.length > 0 && <Legend width={"100%"} />}
                <Line
                  name="Total Volume"
                  type="monotone"
                  dataKey="volume"
                  stroke="#3843AC"
                  activeDot={{ r: 8 }}
                />

                {graphData?.length > 0 && (
                  <>
                    {graphData[0]?.connections?.slice(0, 5)?.map((graphItem, idx) => (
                      <Line
                        name={graphItem.name}
                        type="monotone"
                        dataKey={"connectionVolume_" + (idx + 1)}
                        stroke={graphItem.color}
                        activeDot={{ r: 8 }}
                      />
                    ))}
                  </>
                )}
              </LineChart>
            </ResponsiveContainer>
          </Box>
          {graphData.length === 0 && (
            <NoDataAailable>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "11.875rem", height: "11.875rem" }}>
                  <img
                    src={noConfigImg}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </Box>
                <NoDataAailableHeading>
                  No data available!
                </NoDataAailableHeading>
              </Box>
            </NoDataAailable>
          )}
        </VolumnContainer>
        {/* Connecion container */}
        <ConnecionWrapper setStep={setStep} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              mx: "auto",
              mt: "0.5rem",
              // border: '1px solid black',
              mb: setStep !== undefined || connectors?.length === 0 ? 0 : 8,
            }}
          >
            <ConnectionHeading>
              Connections ({connectors?.length})
            </ConnectionHeading>
            {/* {connectors?.length > 0 &&
              <img alt='' src={sortDown} style={{ width: "1.5rem", height: 24 }}></img>
            } */}
          </Box>
          {
            connectors?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // border: "1px solid #DEDEDE", borderRadius: 2,
                  width: setStep !== undefined ? "85%" : "100%",
                  mb: "2.5rem",
                  mx: "auto",
                  // border: '1px solid black'
                }}
              >
                {connectors?.map((item, idx) => (
                  <DashboardSingleConnector
                    key={idx}
                    data={item}
                    id={idx}
                    deleteConnector={deleteConnector}
                  />
                ))}
              </Box>
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                paddingBottom={4}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "2rem",
                  }}
                >
                  <Box sx={{ width: "11.875rem", height: "11.875rem" }}>
                    <img
                      src={noConfigImg}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: "#25262D",
                      textAlign: "center",
                      fontSize: "1.125rem",
                      fontWeight: 700,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    No connections available!
                  </Typography>
                </Box>
              </Box>
            )
            // <Box sx={{
            //   display: "flex", flexDirection: "column", justifyContent: "center",
            //   alignItems: "center", px: "1.5rem", width: setStep !== undefined ? '85%' : "100%",
            //   height: 130, mx: "auto",
            //   // border: '1px solid black'
            // }}>
            //   <img alt='' src={noConnections} style={{ width: 250, height: 106 }}></img>
            // </Box>
          }
        </ConnecionWrapper>
      </Box>
    </AppLayout>
  );
};

export default DashBoard;

