import { Box, Button, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'


import InfoIcon from '../../assets/NewInfo.svg'
import StreamingIcon1 from '../../assets/straming (2).svg'
import StreamingIcon from '../../assets/stramin.svg'
import batchIcon from '../../assets/batch (2).svg'
import batchIcon1 from '../../assets/Group 82.svg'
import ActiveTier from '../../assets/ActiveTier.svg'
import CommonButton from '../../components/CommonButton/CommonButton'
import { useGetTablePricingMutation } from '../../services/query'
import InfoModal from './infoModal'
import { AnnualLicenseBox, BatchWrapper, BatchdHading, IsStreaming, MonthlyActive, PayAsYouGo, PayAsYouGoBox, Savings, TabelCellBox, TabelCellContent, TabelPricingHeader, TabelPricingWrapper, TableCellStyled, TableHeadStyled, TablePricingMargin, TablePricingPage, TableStyled, UnlimitedHading, UnlimitedWrapper } from './index.styles'
// import { TabelPricing} from './DummyContent'



const TablePricing = ({ setFormdata, formdata, currentPlan, paramsId, name }) => {
    const Tiers = formdata?.cpcRate ? formdata?.cpcRate : 3
    const [isStreaming, setIsStreaming] = useState(true);
    const [isBatch, setIsBatch] = useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [editedData, setEditedData] = useState([]);
    const [newDefaultPricing, setNewDefaultPricing] = useState([])
    const [newPricing, setNewPricing] = useState([])
    const [blurData, setBlurData] = useState([])
    const [discount, setDiscount] = useState(0)
    const [open, setOpen] = React.useState(false);
    const [tier, setTier] = useState(0)
    const [minConst, setMinCost] = useState(0)
    const [infoOpen, setInfoOpen] = useState(false)

    // For Testing
    // const data = TabelPricing
    const [getTablePricing, { data , isLoading, error }] = useGetTablePricingMutation(); //data

    const convertToArrayOfObjects = (arr1, arr2, isStreaming) => {
        const resultArray = [];
        for (let index = 0; index < arr1.length; index++) {
            const obj = {};
            obj["tier"] = arr2[index][0];
            obj["mac"] = arr2[index][1];
            obj["bmc"] = arr1[index][3];
            obj["ic"] = arr2[index][3];
            const saving = ((arr1[index][3] - arr2[index][3]) / arr1[index][3]) * 100;
            obj["saving"] = parseFloat(saving).toFixed(2);
            if (arr2[index][4] !== undefined) {
                obj["extra"] = arr2[index][4];
            }
            resultArray.push(obj);
        }
        return resultArray;
    };
    const convertToSingleArrayOfObjects = (arr) => {
        const resultArray = [];
        for (let index = 0; index < arr.length; index++) {
            const obj = {};
            obj["tier"] = arr[index][0];
            obj["mac"] = arr[index][1];
            obj["bmc"] = arr[index][2];
            obj["ic"] = arr[index][3];
            const saving = ((arr[index][2] - arr[index][3]) / arr[index][2]) * 100;
            const savingforBatch = arr[index][4] + discount
            obj["saving"] = parseFloat(isBatch ? savingforBatch : saving).toFixed(2);
            if (arr[index][4] !== undefined) {
                obj["extra"] = arr[index][4];
            }
            resultArray.push(obj);
        }
        return resultArray;
    };

    const handleChangeDiscount = (e) => {
        let newValue = parseFloat(e.target.value)
        if (newValue < 18) {
            newValue = 18;
        } else if (newValue > 25) {
            newValue = 25;
        }
        newValue = Math.round(newValue * 100) / 100;
        setDiscount(newValue)
    };

    useEffect(() => {
        const types = isStreaming ? "cpc" : "cpmr"
        getTablePricing({ type: types, paramsId })
    }, [isStreaming])


    const handleRadioChange = (selectedItem) => {
        setTier(selectedItem.tier)

    };
    useEffect(() => {
        if (data !== undefined) {
            if (data?.statusCode === 200) {
                // data?.response.pricing.newPricing ?
                let defaultPricing = data?.response?.pricing?.defaultPricing;
                let newPricing = data?.response?.pricing?.newPricing;
                const defaultMonthPricing = defaultPricing[0].pricing;
                const defaultAnnualPricing = defaultPricing[1].pricing;
                let newMonthlyPricing;
                let newAnnualPricing;
                if (newPricing.length === 0) {
                    newMonthlyPricing = defaultPricing[0]?.pricing;
                    newAnnualPricing = defaultPricing[1]?.pricing;
                } else {
                    if (newPricing.length == 1) {

                        if (newPricing[0].plan?.toLowerCase() === "annual") {
                            newAnnualPricing = newPricing[0]?.pricing;
                            newMonthlyPricing = defaultPricing[0]?.pricing;
                        }
                        else {
                            newMonthlyPricing = newPricing[0]?.pricing;
                            newAnnualPricing = defaultPricing[1]?.pricing;
                        }
                    } else {
                        if (newPricing[0].plan?.toLowerCase() === "annual") {
                            newAnnualPricing = newPricing[0]?.pricing;
                            newMonthlyPricing = newPricing[1]?.pricing;
                        }
                        else {
                            newMonthlyPricing = newPricing[0]?.pricing;
                            newAnnualPricing = newPricing[1]?.pricing;
                        }
                    }
                }
                setMinCost(defaultPricing[1]?.minCost)
                const datas = convertToArrayOfObjects(defaultMonthPricing, defaultAnnualPricing)
                setEditedData(structuredClone(datas))
                const newPricingConvertedData = convertToArrayOfObjects(newMonthlyPricing, newAnnualPricing)
                setNewPricing(structuredClone(newPricingConvertedData))
                setNewDefaultPricing(structuredClone(newPricingConvertedData))

                // newPricing.map((item) => {
                //     const type = isStreaming ? "cpc" : "cpmr"
                //     setDiscount(item.discount || 0)
                // })
            }
        }
    }, [data])

    const convertArrayOfObjectsToArrayOfArrays = (arr) => {
        if (isStreaming) {
            return arr?.map(obj => {
                return [
                    parseFloat(obj?.tier),
                    parseFloat(obj?.mac),
                    parseFloat(obj?.bmc),
                    parseFloat(obj?.ic === null ? 0 : obj?.ic),
                ]
            });
        } else {
            return arr?.map(obj => {
                return [
                    parseFloat(obj?.tier),
                    parseFloat(obj?.mac),
                    parseFloat(obj?.bmc),
                    parseFloat(obj?.ic === null ? 0 : obj?.ic),
                    obj?.extra !== undefined ? parseFloat(obj?.extra) : 0,
                ]
            });
        }
    };

    const handleBlur = (e, index) => {
        const field = isBatch ? "extra" : "ic";
        const tiers = "tier"
        const newValue = parseFloat(Number(e.target.value).toFixed(2));
        const updatedData = structuredClone(newPricing);
        const defaultData = structuredClone(editedData);
        if (!isBatch) {
            if (newValue >= minConst) {
                updatedData[index][field] = newValue;
                for (let i = index; i < updatedData?.length; i++) {

                    let value = updatedData[i][field];
                    if (updatedData[i][tiers] === tier) {
                        for (let j = 0; j < i; j++) {
                            updatedData[j][field] = newValue;
                            updatedData[i][field] = newValue;
                        }
                        continue;
                    }
                    else if (value <= minConst) {
                        break;
                    } else if (value >= newValue) {
                        value = parseFloat(newValue.toFixed(2));
                    } else {
                        value = newPricing[i][field] < newValue ? newPricing[i][field] : parseFloat(newValue.toFixed(2));
                    }
                    updatedData[i][field] = value;
                }
            }
        } else {
            updatedData[index][field] = newValue;
        }
        const blurs = convertArrayOfObjectsToArrayOfArrays(updatedData)
        const finalData = convertToSingleArrayOfObjects(blurs)
        setBlurData(isBatch ? updatedData : finalData);
        setNewPricing(updatedData);
    };


    const handlePlanSave = () => {
        const resultArrayOfArrays = convertArrayOfObjectsToArrayOfArrays(newPricing)
        let payload;
        if (!isBatch) {
            payload = {
                type: "cpc",
                discount: isStreaming ? "" : discount,
                tier: tier,
                term: 12,
                price: resultArrayOfArrays,
                annualSpend: 0
            }
        } else {
            payload = {
                type: "cpmr",
                discount: isStreaming ? "" : discount,
                tier: tier,
                term: 12,
                price: newPricing?.map((obj, index) =>
                    [parseFloat(obj?.tier),
                    parseFloat(obj?.mac),
                    parseFloat(obj?.bmc),
                    parseFloat(editedData[index].ic),
                    obj?.extra !== undefined ? parseFloat(obj?.extra) : 0,]
                ),
                annualSpend: 0
            }
        }
        setOpen(false)
    }


    const handleCancel = () => {
        setNewPricing(newDefaultPricing)
        setDiscount(formdata.cpmrDiscount)
        setEditMode(false)
        setTier(Tiers)
    }
    const handleClick = () => {
        setIsStreaming(false);

        setTimeout(() => {
            setIsStreaming(true);
        }, 10);
    };

    const boxRef = useRef(null);
    const scrollBottom = () => {
        setTimeout(() => {
            boxRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 800);
    }


    return (
        <>
            <TablePricingMargin>
            </TablePricingMargin>
            <TabelPricingWrapper className={` Table-contain ${isStreaming ? "second" : "first"}`} isStreaming={isStreaming} >
                {/* Header */}
                <Box className="Table-inner-contain1">
                    {/* <TabelPricingHeader>
                        <UnlimitedWrapper className="CommonTag" isStreaming={isStreaming}
                            onClick={() => {
                                setEditMode(false)
                                setIsStreaming(true)
                                setIsBatch(false)
                                handleClick()
                                scrollBottom()
                            }}>
                            <UnlimitedHading className='TagTypograpy' isStreaming={isStreaming}>
                                <img className='img-style' alt="" src={isStreaming ? batchIcon : StreamingIcon}></img>
                                Unlimited
                            </UnlimitedHading>
                        </UnlimitedWrapper>
                        <BatchWrapper className="CommonTag" isBatch={isBatch}
                            onClick={() => {
                                setEditMode(false)
                                setIsStreaming(false)
                                setIsBatch(true)
                                scrollBottom()
                            }} >
                            <BatchdHading className='TagTypograpy' isBatch={isBatch}>
                                <img className='img-style' src={isStreaming ? StreamingIcon1 : batchIcon1}></img>
                                Batch
                            </BatchdHading>
                        </BatchWrapper>
                    </TabelPricingHeader> */}

                    <Box className="Icon-main-contents">
                        {editMode ?
                            <>
                                {isBatch && (
                                    <>
                                        <leble style={{ color: "#66696A", fontSize: "16px" }}>Discount</leble>
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            multiline
                                            value={discount === 0 ? formdata.cpmrDiscount : discount}
                                            onChange={(e) => setDiscount(e.target.value)}
                                            onBlur={(e) => handleChangeDiscount(e)}
                                            maxRows={4}
                                            sx={{ padding: "10px" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <span style={{ fontSize: "16px", fontFamily: "Helvetica", fontWeight: 600, color: "#25262D", fontSize: "16px" }}>%</span>
                                                ), style: { padding: "10px", borderRadius: "10px", width: "130px", fontFamily: "Helvetica", color: "#25262D", fontSize: "16px", fontWeight: 500, }
                                            }}
                                        />
                                    </>
                                )}
                                <Button className='Cancel-btn' onClick={handleCancel}>Cancel</Button>
                                <CommonButton
                                    width={106}
                                    height={40}
                                    textStyle={{
                                        fontSize: "1rem",
                                        fontWeight: 500,
                                        fontFamily: "Helvetica",
                                        letterSpacing: "normal",
                                    }}
                                    buttonStyle={{ borderRadius: '10px' }}
                                    customStyle={{
                                        alignItems: "center",
                                    }}
                                    title={"Save"}
                                    color="#000"
                                    onClick={() => setOpen(true)}
                                ></CommonButton>
                            </>
                            :
                            <Box className="Icon-main-contents">
                                <img className='img-style' alt="" src={InfoIcon} onClick={() => setInfoOpen(true)}></img>
                            </Box>
                        }

                    </Box>
                </Box>
                {/* Table Content  */}

                <TableContainer id='boxShadowTable' component={Paper}>
                    <TableStyled>
                        <TableHeadStyled>
                            <AnnualLicenseBox
                                isBatch={isBatch}
                                name={name}>Annual License</AnnualLicenseBox>
                            <TableRow>
                                <TableCellStyled >Tier</TableCellStyled>
                                <MonthlyActive> {isStreaming ? "Monthly Active Connections" : "Rows Transferred (Millions)"}</MonthlyActive>
                                <PayAsYouGo>
                                    <PayAsYouGoBox>Pay-as-you-go</PayAsYouGoBox>
                                    {isStreaming ? "Cost/Connection" : "Cost/Million Rows"}
                                </PayAsYouGo>
                                <IsStreaming widthData={"24%"}>
                                    {isStreaming ? "Cost/Connection" : "Cost/Million Rows"}
                                </IsStreaming>
                                {isBatch && name ? <TableCell style={{
                                    textAlign: "center", padding: "12px", width: "15%", color: "#1C2B47", fontWeight: 400, fontSize: "1rem"
                                }}>

                                    Extra %
                                </TableCell> : <></>}
                                <Savings>
                                    {isStreaming ? "Savings" : "Savings(%)"}
                                </Savings>
                            </TableRow>
                        </TableHeadStyled>

                        <TableBody>
                            {
                                isBatch ? (
                                    newPricing?.map((item, index) => {
                                        const isTier = item?.tier < tier;
                                        const bmc = item.bmc;
                                        const Extra = currentPlan?.toLowerCase() !== "monthly" ? item.extra : 0
                                        const saving = Number(discount === 0 ? formdata.cpmrDiscount : discount) + Number(Extra)
                                        const ic = bmc - (bmc * saving) / 100;
                                        return (
                                            <>
                                                <TableRow key={Math.random()} className='tableRowRadius' sx={{
                                                    columnGap: "50px",
                                                    background: isBatch ? "" : index === (tier - 1) ? '#e6e6e6' : '',
                                                }}>
                                                    {editMode && isStreaming ?
                                                        <TableCell className='customTableRow' id='fontFamilyTable' style={{ border: "none", textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, padding: "0px 16px", borderTopLeftRadius: isBatch ? "" : index === (tier - 1) ? "10px" : '', borderBottomLeftRadius: isBatch ? "" : index === (tier - 1) ? "10px" : '' }} > 
                                                        <Box sx={{ position: "relative", borderBottom: index === (tier - 1) && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px" }}>
                                                            <input
                                                                type="radio"
                                                                name={`selectRow`}
                                                                value={item.tier}
                                                                checked={tier === item.tier}
                                                                onChange={() => handleRadioChange(item)}
                                                                style={{ position: "absolute", left: 12, top: 20, width: "fit-content" }}
                                                            />
                                                            {!isLoading ? <> {item.tier}</>
                                                                : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                        </Box>
                                                        </TableCell>
                                                        : <TabelCellContent className='customTableRow' id='fontFamilyTable' isBatch={isBatch} index={index} tier={(tier - 1)} >
                                                            <TabelCellBox index={index}
                                                              tier={ (tier - 1)}
                                                              isBatch={isBatch}
                                                              editedData={editedData.length }
                                                              >
                                                                {!isLoading ? <> {item.tier}</>
                                                                    : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                            </TabelCellBox>
                                                        </TabelCellContent>
                                                    }
                                                    <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' 
                                                    style={{ textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 16px", }}>
                                                        <Box sx={{ borderBottom: index === (tier - 1) && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px" }}>
                                                            {!isLoading ? <> {new Intl.NumberFormat("en-US").format(item.mac)} </>
                                                                : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{ textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 16px", }}>

                                                        <Box sx={{
                                                            background: isBatch ? "rgba(83, 139, 244, 0.1)" : index === (tier - 1) ? '' : "rgba(83, 139, 244, 0.1)", borderBottom: index === (tier - 1) && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px", borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "",
                                                            borderBottomRightRadius: index === editedData.length - 1 ? "20px" : "",
                                                        }}>
                                                            {!isLoading ? <> ${Number(item.bmc).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </>
                                                                : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                        </Box>

                                                    </TableCell>
                                                   
                                                    <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{ textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 0px 0px 16px", }}>
                                                        <Box sx={{
                                                            background: isBatch ? "rgba(83, 139, 244, 0.1)" : index === (tier - 1) ? '' : "rgba(83, 139, 244, 0.1)", borderBottom: index === (tier - 1) && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px",
                                                            borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "",
                                                        }}>
                                                            {!isLoading ? <>  ${(Number(ic).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} </>
                                                                : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                        </Box>
                                                    </TableCell>
                                                    {isBatch && name ? <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{
                                                        textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 0px 0px 0px",
                                                    }}>
                                                        {editMode && !isStreaming ? (
                                                            <>
                                                                <TextField defaultValue={Extra} id="standard-basic" variant="standard" size="small"
                                                                    // onChange={(e) => handleChange(e, index)}
                                                                    onBlur={(e) => handleBlur(e, index)}
                                                                    InputProps={{ disableUnderline: true }}
                                                                    inputProps={{
                                                                        style: {
                                                                            textAlign: 'center', fontSize: "16px !important",
                                                                            borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "", color: "#538BF4"
                                                                        },
                                                                        type: 'number',
                                                                        step: '1',
                                                                        pattern: '^\\d*\\.?\\d{0,2}$',
                                                                    }}
                                                                    sx={{
                                                                        width: "-webkit-fill-available", background: index === (tier - 1) && !isBatch ? '' : "rgba(83, 139, 244, 0.1)",
                                                                        borderBottom: index === editedData.length - 1 ? "" : "1px solid #61616124",
                                                                        padding: "13px"
                                                                    }}
                                                                />
                                                            </>
                                                        ) :
                                                            <>  <Box sx={{
                                                                background: isBatch ? "rgba(83, 139, 244, 0.1)" : index === (tier - 1) ? '' : "rgba(83, 139, 244, 0.1)", borderBottom: index === (tier - 1) && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px",

                                                            }}>
                                                                {!isLoading ?
                                                                    <> {parseFloat(Extra).toFixed(2)}%  </> :
                                                                    <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                            </Box> </>}
                                                    </TableCell> : <></>}
                                                    <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{
                                                        textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 0px 0px 0px",
                                                    }}>
                                                        <Box sx={{
                                                            background: isBatch ? "rgba(83, 139, 244, 0.1)" : index === (tier - 1) ? '' : "rgba(83, 139, 244, 0.1)", borderBottom: index === (tier - 1) && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px",
                                                            borderBottomRightRadius: index === editedData.length - 1 ? "20px" : "",
                                                        }}>
                                                            {!isLoading ?
                                                                <> {parseFloat(saving).toFixed(2)}%  </> :
                                                                <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })
                                ) :
                                    newPricing?.map((item, index) => {
                                        const isTier = item?.tier < tier;
                                        const bmc = item.bmc;
                                        const ic = newPricing[index].ic;
                                        const saving = ((bmc - ic) / bmc) * 100
                                        return (
                                            <>
                                                <TableRow key={Math.random()} className='tableRowRadius' sx={{
                                                    columnGap: "50px",
                                                    background: isBatch ? "" : index === Tiers - 1 && currentPlan?.toLowerCase() !== "monthly" ? 'rgba(83, 139, 244, 0.2)' : '',
                                                }}>
                                                    {editMode && isStreaming ?
                                                        <TableCell className='customTableRow' id='fontFamilyTable' style={{ border: "none", textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, padding: "0px 16px", borderTopLeftRadius: isBatch ? "" : index === (Tiers - 1) ? "10px" : '', borderBottomLeftRadius: isBatch ? "" : index === (Tiers - 1) ? "10px" : '' }} >
                                                            <Box sx={{ position: "relative", borderBottom: index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px" }}>
                                                                <input
                                                                    type="radio"
                                                                    name={`selectRow`}
                                                                    value={item.tier}
                                                                    checked={tier === item.tier}
                                                                    onChange={() => handleRadioChange(item)}
                                                                    style={{ position: "absolute", left: 12, top: 20, width: "fit-content" }}
                                                                />
                                                                {!isLoading ? <> {item.tier}</>
                                                                    : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                            </Box>
                                                        </TableCell> : <TableCell className='customTableRow' id='fontFamilyTable' style={{ border: "none", textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, padding: "0px 16px", borderTopLeftRadius: isBatch ? "" : index === (Tiers - 1) ? "10px" : '', borderBottomLeftRadius: isBatch ? "" : index === (Tiers - 1) ? "10px" : '' }} >
                                                            <Box sx={{ position: "relative", borderBottom: index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px" }}>
                                                                {Tiers === item.tier && !isLoading && (currentPlan === "Annual" || currentPlan === "annual") ? <img
                                                                    style={{ position: "absolute", left: 12, top: 20, width: "fit-content" }}
                                                                    alt=""
                                                                    src={ActiveTier}
                                                                ></img> : ""}
                                                                {!isLoading ? <> {item.tier}</>
                                                                    : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                            </Box>
                                                        </TableCell>
                                                    }



                                                    <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{ textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 16px", }}>

                                                        <Box sx={{ borderBottom: index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px" }}>
                                                            {!isLoading ? <> {new Intl.NumberFormat("en-US").format(item.mac)} </>
                                                                : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{ textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 16px", }}>

                                                        <Box sx={{
                                                            background: isBatch ? "rgba(83, 139, 244, 0.1)" : index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" ? '' : "rgba(83, 139, 244, 0.1)", borderBottom: index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px", borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "",
                                                            borderBottomRightRadius: index === editedData.length - 1 ? "20px" : "",
                                                        }}>
                                                            {!isLoading ? <> ${Number(item.bmc).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </>
                                                                : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                        </Box>

                                                    </TableCell>
                                                    <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{ textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 0px 0px 16px", }}>
                                                        {editMode && isStreaming ? (
                                                            <>
                                                                <TextField defaultValue={item.ic} id="standard-basic" variant="standard" size="small"
                                                                    onBlur={(e) => handleBlur(e, index)} InputProps={{ disableUnderline: true }}
                                                                    inputProps={{
                                                                        style: {
                                                                            textAlign: 'center', fontSize: "16px !important",
                                                                            borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "", color: "#538BF4"
                                                                        },
                                                                        type: 'number',
                                                                        step: '1',
                                                                        pattern: '^\\d*\\.?\\d{0,2}$',
                                                                    }}
                                                                    sx={{
                                                                        width: "-webkit-fill-available", background: index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" && !isBatch ? '' : "rgba(83, 139, 244, 0.1)",
                                                                        borderBottom: index === editedData.length - 1 ? "" : "1px solid #61616124",
                                                                        borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "", padding: "13px"
                                                                    }}
                                                                />
                                                            </>
                                                        ) :
                                                            <>
                                                                <Box sx={{
                                                                    background: isBatch ? "rgba(83, 139, 244, 0.1)" : index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" ? '' : "rgba(83, 139, 244, 0.1)", borderBottom: index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px",
                                                                    borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "",
                                                                }}>
                                                                    {!isLoading ? <>  ${(Number(item.ic).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} </>
                                                                        : <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                                </Box>
                                                            </>
                                                        }
                                                    </TableCell>
                                                    {isBatch ? <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{
                                                        textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 0px 0px 0px",
                                                    }}>
                                                        {editMode && !isStreaming ? (
                                                            <>
                                                                <TextField defaultValue={item.extra} id="standard-basic" variant="standard" size="small"
                                                                    // onChange={(e) => handleChange(e, index)}
                                                                    onBlur={(e) => handleBlur(e, index)}
                                                                    InputProps={{ disableUnderline: true }}
                                                                    inputProps={{
                                                                        style: {
                                                                            textAlign: 'center', fontSize: "16px !important",
                                                                            borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "", color: "#538BF4"
                                                                        },
                                                                        type: 'number',
                                                                        step: '1',
                                                                        pattern: '^\\d*\\.?\\d{0,2}$',
                                                                    }}
                                                                    sx={{
                                                                        width: "-webkit-fill-available", background: index === (Tiers - 1) && !isBatch ? '' : "rgba(83, 139, 244, 0.1)",
                                                                        borderBottom: index === editedData.length - 1 ? "" : "1px solid #61616124",
                                                                        borderBottomLeftRadius: index === editedData.length - 1 ? "20px" : "", padding: "13px"
                                                                    }}
                                                                />
                                                            </>
                                                        ) :
                                                            <>  <Box sx={{
                                                                background: isBatch ? "rgba(83, 139, 244, 0.1)" : index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" ? '' : "rgba(83, 139, 244, 0.1)", borderBottom: index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px",

                                                            }}>
                                                                {!isLoading ?
                                                                    <> {parseFloat(item.extra).toFixed(2)}%  </> :
                                                                    <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                            </Box> </>}
                                                    </TableCell> : <></>}
                                                    <TableCell className={editMode ? 'customTableRow1' : 'customTableRow'} id='fontFamilyTable' style={{
                                                        textAlign: "center", color: "#616161", fontSize: "16px", fontWeight: 500, border: "none", padding: "0px 0px 0px 0px",
                                                    }}>
                                                        <Box sx={{
                                                            background: isBatch ? "rgba(83, 139, 244, 0.1)" : index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" ? '' : "rgba(83, 139, 244, 0.1)", borderBottom: index === (Tiers - 1) && currentPlan?.toLowerCase() !== "monthly" && !isBatch ? "none" : index === editedData.length - 1 ? "" : "1px solid #61616124", padding: "16px",
                                                            borderBottomRightRadius: index === editedData.length - 1 ? "20px" : "",
                                                        }}>
                                                            {!isLoading ?
                                                                <> {parseFloat(saving).toFixed(2)}%  </> :
                                                                <Skeleton variant="rounded" width="100%" height={22.8} />}
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })
                            }
                        </TableBody>
                    </TableStyled>
                </TableContainer>
            </TabelPricingWrapper>
            <InfoModal setInfoOpen={setInfoOpen} infoOpen={infoOpen} ModalName="table" isBatch={isBatch} />
            <div ref={boxRef}></div>
        </>
    )
}

export default TablePricing