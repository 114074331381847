import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "1rem !important",
  alignItems: "center",
});

export const Heading = styled(Typography)({
  fontSize: "1.25rem !important",
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
  marginTop: "6px !important"
});

export const Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: "6.25rem",
  width: "100%",
  marginTop: "0.5rem !important",
  marginBottom: "0.5rem !important",
});

export const ConnectionCard = styled(Box)({
  flex: 1.5,
  width: "19% !important",
  height: "6.25rem",
  borderRadius: "0.25rem",
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
  backgroundColor: "#fff",
  marginRight: "0.5rem !important",
  padding: "1rem !important",
  display: "flex",
  flexDirection: "row",
});

export const NoOfValueOfCard = styled(Typography)({
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "1.5rem !important ",
  color: "#25262D",
});

export const CardHeading = styled(Typography)({
  fontWeight: "400 !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "0.875rem !important",
  color: "#3C4049",
  marginTop: "0.5rem !important",
});

export const AveragedailyCard = styled(Box)({
  flex: 2.1,
  width: "25%",
  height: "6.25rem",
  borderRadius: 1,
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
  backgroundColor: "#fff",
  marginRight: "0.5rem !important",
  paddingLeft: "1rem !important",
  paddingTop: "1rem !important",
  paddingBottom: "1rem !important",
  display: "flex",
  flexDirection: "row",
});

export const AveragedailyCardHeading = styled(Typography)({
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "1.5rem !important",
  color: "#25262D",
});

export const AverageMonthlyCard = styled(Box)({
  flex: 2.1,
  width: "25%",
  height: "6.25rem",
  borderRadius: 1,
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
  backgroundColor: "#fff",
  marginRight: "0.5rem",
  paddingLeft: "1rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  display: "flex",
  flexDirection: "row",
});

export const CurrentMonthCard = styled(Box)({
  flex: 2.1,
  width: "25%",
  height: "6.25rem",
  borderRadius: 1,
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
  backgroundColor: "#fff",
  marginRight: 0,
  paddingLeft: "1rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  display: "flex",
  flexDirection: "row",
});

export const VolumnContainer = styled("Box")({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  backgroundColor: "#fff",
  borderRadius: "8px",
  marginTop: "1rem",
  padding: "1rem",
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
  width: "1200",
  height: "25rem",
});

export const VolumnHeading = styled("Typography")({
  color: "#000000",
  fontSize: "1.25rem !important",
  fontWeight: "500 !important",
  fontFamily: "Helvetica Neue !important",
});

export const NoDataAailable = styled(Box)({
  position: "absolute",
  top: "40%",
  left: "40%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "5rem",
});

export const NoDataAailableHeading = styled("Typography")({
  color: "#25262D",
  textAlign: "center",
  fontSize: "1.125rem !important",
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
});


export const ConnecionWrapper = styled(Box)({
  backgroundColor: "#fff",
  marginTop: (props) => (props.setStep !== undefined ? 0 : "16px"),
  paddingLeft: (props) => (props.setStep !== undefined ? 0 : "16px"),
  paddingRight: (props) => (props.setStep !== undefined ? 0 : "16px"),
  paddingTop: (props) => (props.setStep !== undefined ? 0 : "8px"),
  borderRadius: "8px",
  marginTop: "1rem",
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
})

export const ConnectionHeading = styled("Typography")({
  color: "#000000",
  fontSize: "1.25rem !important",
  fontWeight: "500 !important",
  fontFamily: "Helvetica Neue !important",
});
