import { useMemo, useEffect } from "react";

const useFilterConnectors = ({ allCategories, selectedCategories, allPlatforms, setPlatforms }) => {
    const integrationTypeFilters = useMemo(() => {
        const category = allCategories.find(category => category.name === "Integration Type");
        if (!category) return []

        return category.subCategories.map(scItem => scItem.name);
    }, [allCategories]);
    const functionalityFilters = useMemo(() => {
        const category = allCategories.find(category => category.name === "Functionality");
        if (!category) return []

        return category.subCategories.map(scItem => scItem.name);
    }, [allCategories]);
    const platformTypeFilters = useMemo(() => {
        const category = allCategories.find(category => category.name === "Platform Type");
        if (!category) return []

        return category.subCategories.map(scItem => scItem.name);
    }, [allCategories]);

    useEffect(() => {
        if (selectedCategories?.length > 0 && allPlatforms?.length > 0) {
            let newPlatforms = [...allPlatforms];
            let integrationTypeCategories = selectedCategories.filter(scItem => integrationTypeFilters.includes(scItem));
            let platformTypeCategories = selectedCategories.filter(scItem => platformTypeFilters.includes(scItem));
            let functionalityCategories = selectedCategories.filter(scItem => functionalityFilters.includes(scItem));
      
            if(integrationTypeCategories.length > 0) {
              integrationTypeCategories = integrationTypeCategories.map(item => item.toLowerCase());
              newPlatforms = newPlatforms.filter(platform => integrationTypeCategories.includes(platform?.supportedType?.toLowerCase()));
            }
      
            if (platformTypeCategories.length > 0) {
              platformTypeCategories = platformTypeCategories.map(item => item.toLowerCase());
              newPlatforms = newPlatforms.filter((platform) => platformTypeCategories.includes(platform?.platformType?.toLowerCase()));
            }
            
            if (functionalityCategories.length > 0) {
              functionalityCategories = functionalityCategories.map(item => item.toLowerCase());
              newPlatforms = newPlatforms.filter((platform) => functionalityCategories.includes(platform?.dataTypeCategory?.toLowerCase()));
            }
            setPlatforms(newPlatforms);
        } else {
            setPlatforms(allPlatforms)
        }
    }, [selectedCategories, allPlatforms])
}

export default useFilterConnectors;