import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "You do not have permission to access this feature. Kindly contact your administrator.",
  severity: "",
  open: false,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackbar: (state, { payload }) => {
      state.message = 'You do not have permission to access this feature. Kindly contact your administrator.';
      state.severity = payload.severity;
      state.open = false;
    },
    closeSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const { setSnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
