import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import AppLayout from "../../../components/AppLayout/AppLayout";
import { color } from "../../../utils/Colors";
import {
  useGetASUserMutation,
  useGetSRoleMutation,
  useUpdateSUserMutation,
} from "../../../services/query";
import { madTechState } from "../../../features/madTechSlice";
import SEditUser from "./SEditUser";
import { useMyContext } from "../../../components/CommanToasterContext/toast";
import { Strings } from "../../../utils/Strings";
import Delete from "../Modal/Delete";
import SBreadCrumb from "../../../components/Setting/SBreadCrumb";

const SEditUserForm = () => {
  const { selectedAccount, user } = useSelector(madTechState);

  const location = useLocation();
  const { id } = useParams();
  const queryParams = new URLSearchParams(location?.search);
  const userId = queryParams?.get("userId");
  const navigate = useNavigate();
  const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isUserEdit, setIsUserEdit] = useState(false);

  const [userDetails, setUserDetails] = useState(null);

  // const { data, isLoading, error, refetch: refetchAllAccounts } = useGetAllAccountsQuery();
  const [roles, setRoles] = useState(null);
  const [updateSUser, { data: updateSUserData, error: updateSUserError, isLoading: updateSuserLoading }] =
    useUpdateSUserMutation();

  const [
    getRoleData,
    { data: roleData, isLoading: roleLoading, error: roleError },
  ] = useGetSRoleMutation();
  const [
    getData,
    { data: userData, isLoading: getUserLoading, error: getUserError },
  ] = useGetASUserMutation();

  useEffect(() => {
    if (!id) return;
    getData({
      id,
      userId,
    });
  }, [id, userId]);

  useEffect(() => {
    getRoleData();
  }, []);

  useEffect(() => {
    if (roleData !== undefined) {
      if (roleData.statusCode === 200 && roleData.response) {
        setRoles(roleData.response);
      } else {
        setRoles([]);
      }
    }

    if (roleError !== undefined) {
      setRoles([]);
      console.log("roleError", roleError);
    }
  }, [roleData, roleError]);

  useEffect(() => {
    if (
      userData !== undefined &&
      userData.statusCode === 200 &&
      userData.response
    ) {
      var response = {
        ...userData.response,
        accounts: userData.response.accountList,
      };
      setUserDetails(response);
    }

    if (getUserError !== undefined) {
      console.log("error", getUserError);
      setUserDetails({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        companyName: user?.companyName,
        title: "",
        roleId: "",
        accounts: [],
      });
    }
  }, [userData, getUserError]);

  useEffect(() => {
    if (updateSUserData !== undefined) {
      if (updateSUserData.statusCode === 200) {
        setMessage(updateSUserData?.statusMessage);
        setOpen(true);
        navigate(
          selectedAccount ? "/users?accountId=" + selectedAccount.id : "/users"
        );
        return;
      } else {
        setOpen2(true);
        setMessage2(updateSUserData.statusMessage);
      }
    }
    if (updateSUserError !== undefined) {
      console.log("updateSUserError", updateSUserError);
    }
  }, [updateSUserData, updateSUserError]);

  const _handleUpdateUser = (data) => {

    updateSUser(data ? data : userDetails);
  };

  const handleNavigate = () => {
    navigate(
      selectedAccount ? "/users?accountId=" + selectedAccount.id : "/users"
    );
  };

  return (
    <AppLayout>
      <Box sx={{ px: "1.5rem" }}>
        <SBreadCrumb mainPathName="Settings" pathName={userDetails?.name} ModulepathName="Roles" modulePath="roles" state={isUserEdit} setState={setIsUserEdit} />
        {roleLoading ||
          roles == null ||
          getUserLoading ||
          userDetails === null ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "75vh",
            }}
          >
            <CircularProgress size={45} thickness={8}></CircularProgress>
          </Box>
        ) : (
          <SEditUser
            roles={roles}
            _handleUpdateUser={_handleUpdateUser}
            isUserEdit={isUserEdit}
            setIsUserEdit={setIsUserEdit}
            setIsDeleteOpen={setIsDeleteOpen}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            updateSuserLoading={updateSuserLoading}
          />
        )}
      </Box>

      {/* DELETE ACCOUNT MODAL START */}
      <Delete
        setIsUserEdit={setIsUserEdit}
        isDeleteOpen={isDeleteOpen}
        selectedItem={userDetails}
        getSUser={handleNavigate}
        setIsDeleteOpen={setIsDeleteOpen}
        setSelectedItem={() => { }}
      />
      {/* DELETE ACCOUNT MODAL END */}
    </AppLayout>
  );
};

export default SEditUserForm;
